import { Component, HostBinding } from '@angular/core';
import { AppMain } from './app.main';
import { AuthService, ScreenService, AppInfoService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }
  userName: string = '';

  constructor(private authService: AuthService, private screen: ScreenService,
    public appInfo: AppInfoService,
    public appMain: AppMain
  ) {

  }

  isAuthenticated() {
    // check the use is logedin or not
    //return this.appMain.isUserLoginIn;

    return this.authService.loggedIn;
  }

  ngOnInit(): void {

    this.userName = localStorage.getItem("boUserName");
  }


  logout() {
    // delete the saved informations
    localStorage.removeItem("boUserToken");
    localStorage.removeItem("boUserId");
    localStorage.removeItem("boIsLoggedIn");
    localStorage.removeItem("boFirstName");
    this.authService.logOut();
    //this.appMain.isUserLoginIn =false;
  }


}
