<form (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

    <dxi-item dataField="password" editorType="dxTextBox"
      [editorOptions]="{ mode: 'password' }">
      <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
    
    </dxi-item>

    <dxi-item dataField="confirmedPassword" editorType="dxTextBox"
      [editorOptions]="{ mode: 'password' }">
      <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
      <dxi-validation-rule type="custom" message="Passwords do not match" [validationCallback]="confirmPassword">
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'changePasswordTemplate'">
      </dxo-button-options>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'changePasswordTemplate'">
      <span class="dx-button-text">
        <ng-container *ngIf="loading; else notLoading">
          <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
        </ng-container>
        <ng-template #notLoading>Continue</ng-template>
      </span>
    </ng-container>

  </dx-form>
</form>
