<div class="top-menu">
<dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
</dx-menu>
</div>


<div class="content-block dx-card responsive-paddings">

  <dx-form #form userName="form" [colCount]="3" [(formData)]="formData">
    <dxi-item itemType="group" [colSpan]=3 [colCount]="1" caption="User">
      <dxi-item dataField="employeeId" [visible]=false></dxi-item>
      <dxi-item dataField="userName" [label]="{text:('User Name')}"
      [editorOptions]="{ maxLength: '15'}" [disabled]="visible">
        <dxi-validation-rule type="required" message="User Name  is required">
        </dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [max]="225" message="User Name length must be less than 225">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="storeId" [label]="{text: ('Store')}" editorType="dxSelectBox" [editorOptions]="{ items: Stores, searchEnabled: true, value: '', displayExpr:'storeName',
      valueExpr:'storeId' }">
      <dxi-validation-rule type="required" message="Store  is required">
      </dxi-validation-rule></dxi-item>
      <dxi-item dataField="employeeName" [label]="{text:('Employee Name')}"
      [editorOptions]="{ maxLength: '225'}">
        <dxi-validation-rule type="required" message="Employee Name  is required">
        </dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [max]="225" message="Employee Name length must be less than 225">
        </dxi-validation-rule>
      </dxi-item>
      
      <dxi-item   dataField="password"  [editorOptions]="{ mode: 'password' }" >
        <dxi-validation-rule type="required" message="password is required">
        </dxi-validation-rule>
      </dxi-item>
      <!-- <dxi-item dataField="oldPassword">
        <dxi-validation-rule type="required" message="old password is required">
        </dxi-validation-rule>
      </dxi-item> -->
      <!-- <dxi-item dataField="isActive" editorType="dxCheckBox"></dxi-item> -->
      <dxi-item dataField="isAdmin" editorType="dxCheckBox"></dxi-item>
    </dxi-item>

  </dx-form>
</div>
