import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { StoreService } from '../store.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-storelist',
  templateUrl: './storelist.component.html',
  styleUrls: ['./storelist.component.scss']
})
export class StorelistComponent implements OnInit {

  menus: any[];
  dataSource: any;
  formData:store;
  storeId:number; records = [];
  brouchurelist: BrouchureList[] = [];
  videolist: VideoList[] = [];
  rowSelected: boolean = false; isSA = false;
  // brouchureList: BrouchureList[] = [];
  // selectedBrouchureList: BrouchureList[] = [];
  // selectedlanguageId: number;
  @ViewChild("grid") grid: DxDataGridComponent;
  
  constructor(
    public service: StoreService, public db: AngularFireDatabase, private svcAku: SvcAku,
    private router: Router
  ) {
    this.menus = [
      { storeId: "addNew", name: "Add New", icon: "add", location: "after" },
      { storeId: "edit", name: "Edit", icon: "edit", location: "after" },
    // { storeId: "refresh", name: "Refresh", icon: "refresh", location: "after" }
    // { storeId: "delete", name: "Delete", icon: "trash", location: "after" },
    ];
  }

  ngOnInit(): void {
    this.formData = { storeId:0,storeName:"",location:"",contactPerson:"",contactNumber:0,note:"",isActive:true, brouchureList:[], videoList:[] }
    // console.log('get-items-nav');
    // console.log(localStorage.getItem("boUserName"));
    var _loginUserName = localStorage.getItem("boUserName").trim().toLowerCase();
    this.isSA = _loginUserName == 'dev@email.com' || _loginUserName == 'sa@email.com';
    if(this.isSA){
      this.loadGrid();
    }
    else{
      this.menus = [];
    }
    
    this.svcAku.log('P-STORE-LIST');

    //  this.service.getBrouchureByStore(this.storeId).then((res: any) => {
    //    this.formData = res.brouchureList;
    //   if (res.product[0].flavor_option != null) {
    //     this.formData.brouchureList= res.product[0].brouchureList;
    //     this.brouchureList = res.product[0].flavor_option as BrouchureList[];
    //   }
    //   else {
    //     this.formData.brouchureList= [];
    //     this.brouchureList = [];
    //   }
    // })
     this.formData.brouchureList= this.brouchurelist; 
     this.formData.videoList= this.videolist; 
  }
  loadGrid(){
    this.db.list('store').snapshotChanges().subscribe(res=>{
      this.records = [];
      var _resList = this.svcAku.fireRecords(res);
      _resList.forEach(r=>{
        if(r.s != 1) return;
        this.records.push({
          storeId: r.id, storeName: r.n, location: r.l, contactPerson: r.cp, contactNumber: r.cn, note: r.r
        });
      });
      this.dataSource = this.records;

      var _langAll = [];
      this.db.list('lang').snapshotChanges().subscribe(resLang=>{
        _langAll = this.svcAku.fireRecords(resLang).filter(l=>l.s==1);
        this.dataBindBrochureList(_langAll);
        this.dataBindVideoList(_langAll);
      });
    });
    // this.service.getStore().then((res: any) => {
    //   console.log('resItem', res.item);
    //   if (res.status == true) {
    //     this.dataSource = res.item;
    //   }
    // })
  }
  dataBindBrochureList(_langAll){
    this.db.list('brochure').snapshotChanges().subscribe(resBrochure=>{
      var _borchureAll = this.svcAku.fireRecords(resBrochure).filter(b=>b.s!=2);
      _borchureAll.forEach(b=>{
        b.languageName = '';
        var _arrLang = _langAll.filter(l=>l.id == b.langId);
        if(_arrLang.length>0) b.languageName = _arrLang[0].n;
      });
      this.records.forEach(s=>{
        s.brouchureList = [];
        var _storeBrochures = _borchureAll.filter(b=>b.stores && b.stores.filter(bs=>bs.id==s.id && bs.s!=2));
        _storeBrochures.forEach(sb=>{
          s.brouchureList.push({
            brouchureDocument: sb.doc,
            languageName: sb.languageName,
            brouchureTitle: sb.n
          })
        });
      });
    });
  }
  dataBindVideoList(_langAll){
    this.db.list('videos').snapshotChanges().subscribe(resVideo=>{
      var _videoAll = this.svcAku.fireRecords(resVideo).filter(v=>v.s!=2);
      this.records.forEach(s=>{
        s.videoList = [];
        var _storeVideos = _videoAll.filter(v=>v.store && v.store.filter(vs=>vs.id == s.id));
        _storeVideos.forEach(sv=>{
            s.videoList.push({
              videoDocument:sv.fn,
              languageName:'',
              videoTitle:sv.n
            });
        });
      });
    });
    //var _videoAll = thi

  }
  addNew() {
    this.router.navigate(["Store"]);
  }
  edit(storeId: number) {
    this.router.navigate(['Store/' + storeId]);
  }
  // view(storeId: number) {
  //   this.router.navigate(['Storelist/' + storeId]);
  // }

  delete(storeId: number) {
    if ((confirm("Are you sure to delete "))) {
      for (let i = 0; i < this.dataSource.length; ++i) {
        if (this.dataSource[i].storeId === storeId) {
          // this.service.deleteStore(storeId).then((res: any) => {
          //   this.dataSource.splice(i, 1);
          // })
        }
      }
    }
  }
  // dataValidation(): boolean {
  //   this.updateBrouchureList();
  //   this.formData.brouchureList = this.brouchureList;
  //   return true;
  // }
 
  menuClick(e) {
    if (e.itemData.storeId == "addNew") {
      this.addNew();
    }
    else if (e.itemData.storeId == "refresh") {
      this.loadGrid();
    }
    else if (e.itemData.storeId == "edit") {
      console.log(this.grid.instance.getSelectedRowsData()[0]);
      var param = this.grid.instance.getSelectedRowsData()[0].storeId;
      this.edit(param);
    }
    // else if (e.itemData.storeId == "view") {
    //   console.log(this.grid.instance.getSelectedRowsData()[0]);
    //   var param = this.grid.instance.getSelectedRowsData()[0].storeId;
    //   this.view(param);
    // }
    else if (e.itemData.storeId == "delete") {
      var a = this.grid.instance.getSelectedRowsData()[0].storeId;
      this.delete(a);
    }
  }
  Rowdblclick() {
    var param = this.grid.instance.getSelectedRowsData()[0].storeId;
    this.edit(param);
  }
  onContentReady(e) {
    this.grid.instance.selectRowsByIndexes([0]);
  }
  Rowclick() {
    this.rowSelected = true;
    var storeId = this.grid.instance.getSelectedRowsData()[0].storeId;
    var _arr = this.records.filter(s=>s.storeId == storeId);
    if(_arr.length<1){
      alert(`Store ${storeId} not found!`);
      return;
    }
    this.brouchurelist = _arr[0].brouchureList;
    this.videolist = _arr[0].videoList;
    // this.service.getBrouchureByStore(storeId).then((res: any) => {
    //     this.brouchurelist = res.brouchureList;
    // })
    // this.service.getVideoByStore(storeId).then((res: any) => {
    //   this.videolist = res.videoList;
    // })
  }
}

export interface BrouchureList{
  brouchureDocument: string;
  languageName: number;
  brouchureTitle:string;
}
export interface VideoList{
  videoDocument:string;
  videoTitle:string;
}
export interface store{
  storeId:number;
  storeName:string;
  location:string;
  contactPerson:string;
  contactNumber:number;
  note:string;
  brouchureList:BrouchureList[];
  videoList:VideoList[];
  isActive:boolean;
}
