import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '../shared/services';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private route: Router,) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    // check the user is already login or not
    if (localStorage.getItem('boUserToken') != null)
      return true;
    else {
      //this.route.navigateByUrl("Login");
      this.authService.logOut();
      //this.route.navigate(['/login-form'], { queryParams: { returnUrl: state.url } })
    }
    return false;
  }
}
