import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMain } from 'src/app/app.main';

@Injectable({
  providedIn: 'root'
})
export class BrouchureService {

  constructor(
    private http: HttpClient,
    private appMain: AppMain
  ) { }

  public getBrouchure() {
    return this.http.get(this.appMain.apiURL +"Brouchure").toPromise();
  }
  public getBrouchureByBrouchureId(brouchureId:number) {
    return this.http.get(this.appMain.apiURL + "Brouchure/"+ brouchureId).toPromise();
  }
  public saveBrouchure(formData: any) {
    return this.http.post(this.appMain.apiURL + "Brouchure", formData).toPromise();
  }
  public updateBrouchure(brouchureId:number,formData: any) {
    return this.http.put(this.appMain.apiURL + "Brouchure/"+ brouchureId, formData).toPromise();
  }
  public deleteBrouchure(brouchureId: number){
    return this.http.delete(this.appMain.apiURL + "Brouchure/" + brouchureId).toPromise();
  }
  // public getBrouchureQRByBrouchureId(brouchureId:number) {
  //   return this.http.get(this.appMain.apiURL + "Brouchure/"+ brouchureId).toPromise();
  // }
}