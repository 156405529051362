
<div class="top-menu">
    <dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
    </dx-menu>
</div>
  
    
    
    <div class="content-block dx-card responsive-paddings">
    
        <dx-form #form languageId="form" [colCount]="3" [(formData)]="formData">
          <dxi-item itemType="group" [colSpan]=3 [colCount]="1" caption="Language">
            <dxi-item dataField="languageId"  [visible]=false></dxi-item>
            
            <dxi-item dataField="languageName"  [disabled]="true" [editorOptions]="{ maxLength: '50'}" >
              <dxi-validation-rule type="required" message="languageName name is required">
              </dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="50" message="languageName name length must be less than 50" >
              </dxi-validation-rule>
      
            </dxi-item>
             </dxi-item>
          
          
        </dx-form>
      </div>
    
