import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services';
import { LoginService } from './login.service';
import { SvcAuth } from 'src/app/_svcs/auth.svc';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  loading = false;
  returnUrl: string = "Brouchurelist";//"Storelist";
  formData: any = {};

  constructor(
    private authService: AuthService,
    private svcAuth: SvcAuth, private svcAku: SvcAku, private db:AngularFireDatabase,
    private router: Router,
    private route: ActivatedRoute,
    private service: LoginService) { }
    
    async onSubmit(e){
      e.preventDefault();
      const { userName, password } = this.formData;
      this.loading = true;
      //console.log(username, password);
      var _userName = this.formData.userName;
      if(!_userName.includes('@'))
        _userName += '@ebrochure.com';
      //console.log('login:', _userName);
      await this.svcAuth.login(_userName, password).then(res=>{
        //console.log(res);
        //console.log(res.user.uid);
        if(_userName == 'dev@email.com' || _userName == 'sa@email.com'){
          this.navigateToHome(res, userName, password);
          return;
        }
        this.db.list('users').snapshotChanges().subscribe(resUsers=>{
          this.loading = false;
          var _records = this.svcAku.fireRecords(resUsers).filter(r=>r.s!=2);
          var _arr = _records.filter(x=>x.appId == res.user.uid);
          console.log(_arr);
          if(_arr.length<1){
            alert('User not able to find from list!');
            return;
          }
          if(_arr[0].a != 1){
            alert('Only Admin users are allowed!');
            return;
          }
          this.navigateToHome(res, userName, password);
        });
      }).catch(err=>{
        console.log(err);
        this.loading = false;
        alert('Login failed. Tray again!');
      })
    }
    navigateToHome(res, userName, password){
      this.svcAuth.accInfoSet(JSON.stringify({uid: res.user.uid, email: res.user.email}));
      localStorage.setItem('boUserToken', res.user.uid);
      localStorage.setItem("boUserName", res.user.email);
      localStorage.setItem("boUserId", res.user.uid);
      localStorage.setItem("boFirstName", res.user.email);

      localStorage.setItem("boIsLoggedIn", "1");
      const result = this.authService.logIn(userName, password);
      this.loading = false;
      //console.log('nav', this.returnUrl);
      this.svcAku.log('LOGIN-OK');
      this.router.navigate([this.returnUrl]);
    }
  async onSubmitV01(e) {
    e.preventDefault();
    const { username, password } = this.formData;
    this.loading = true;

    // login here
    await this.service.Login(username, password).then((res: any) => {
      if (res.status) {
        localStorage.setItem('boUserToken', res.token);
        // afer login store the current user details in local storeage
        this.service.getCurrentUserDetail(username).then((res: any) => {
          if (res.status) {

            localStorage.setItem("boUserName", res.user[0].userName);
            //localStorage.setItem("GroupId", res.user[0].groupId);
            //localStorage.setItem("GroupName", res.user[0].groupName);
            localStorage.setItem("boUserId", res.user[0].id);
            localStorage.setItem("boFirstName", res.user[0].firstName);

            localStorage.setItem("boIsLoggedIn", "1");
            //localStorage.setItem("Image", res.user[0].image)

            // window.location.assign('/inv'+this.returnUrl);
            // window.location.assign('/inv/index.html');

            const result = this.authService.logIn(username, password);

            this.loading = false;

            this.router.navigate([this.returnUrl]);
          }
          else {
            this.loading=false;
            alert(res.message);
          }
        })
      }
      else {
        this.loading=false;
        alert(res.message);
        return;
      }
    });
  }

  onCreateAccountClick = () => {
    this.router.navigate(['/create-account']);
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent]
})
export class LoginFormModule { }
