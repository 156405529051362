import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { AgenciesService } from '../agencies.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-agencies-list',
  templateUrl: './agencies-list.component.html',
  styleUrls: ['./agencies-list.component.scss']
})
export class AgenciesListComponent implements OnInit {

  menus: any[];
  dataSource: any;
  formData:Agencies;
  agenciesId:number;
  brouchurelist: BrouchureList[] = []; records = [];

  rowSelected: boolean = false;
  @ViewChild("grid") grid: DxDataGridComponent;
  
  constructor(
    public service: AgenciesService, public db: AngularFireDatabase, private svcAku: SvcAku,
    private router: Router
  ) {
    this.menus = [
      { agenciesId: "addNew", name: "Add New", icon: "add", location: "after" },
      { agenciesId: "edit", name: "Edit", icon: "edit", location: "after" },
      // { agenciesId: "refresh", name: "Refresh", icon: "refresh", location: "after" }
      { agenciesId: "delete", name: "Delete", icon: "trash", location: "after" },
    ];
  }

  ngOnInit(): void {
    this.formData = {agenciesId:0,agenciesName:"",agenciesDescription:"" ,brouchureList:[],isActive:true}
    this.loadGrid();
    //this.loadAndMigrate();
    this.formData.brouchureList= this.brouchurelist;
    this.svcAku.log('P-AGENCY-LIST');
  }
  loadGrid() {
    this.db.list('agency').snapshotChanges().subscribe(res=>{
      this.records = [];
      var _resList = this.svcAku.fireRecords(res);
      _resList.forEach(r=>{
        if(r.s != 1) return;
        this.records.push({
          agenciesId: r.id, agenciesName: r.n, agenciesDescription: r.d, appId: r.appId
        });
      });
      this.dataSource = this.records;
      var _langAll = [];
      this.db.list('lang').snapshotChanges().subscribe(resLang=>{
        _langAll = this.svcAku.fireRecords(resLang).filter(l=>l.s==1);
        this.dataBindBrochureList(_langAll);
        //console.log(this.records);
      });
    });
    // this.service.getAgencies().then((res: any) => {
    //   if (res.status == true) {
    //     this.dataSource = res.item;
    //     console.log(res.item);
    //   }
    // })
  }
  dataBindBrochureList(_langAll){
    this.db.list('brochure').snapshotChanges().subscribe(resBrochure=>{
      var _borchureAll = this.svcAku.fireRecords(resBrochure).filter(b=>b.s!=2);
      _borchureAll.forEach(b=>{
        b.languageName = '';
        var _arrLang = _langAll.filter(l=>l.id == b.langId);
        if(_arrLang.length>0) b.languageName = _arrLang[0].n;
      });
      //console.log('b', _borchureAll);
      this.records.forEach(s=>{
        s.brouchureList = [];
        //console.log(s.id);
        var _agencyBrochures = _borchureAll.filter(b=>b.agencies && b.agencies.filter(ba=>ba.id==s.agenciesId && ba.s!=2).length>0);
        _agencyBrochures.forEach(sb=>{
          s.brouchureList.push({
            brouchureDocument: sb.doc,
            languageName: sb.languageName,
            brouchureTitle: sb.n
          })
        });
      });
    });
  }
  loadAndMigrate(){
    this.service.getAgencies().then((res: any) => {
      if (res.status == true) {
        console.log('migrate list:', res.item);
        res.item.forEach(r => {
          var _recId = this.svcAku.stringAppId('A');
          this.db.object(`agency/${_recId}`).set({
            id: r.agenciesId,
            n: r.agenciesName,
            d: r.agenciesDescription,
            s: 1,
            sId: 2
          }).then(r=>{
          }).catch(err=>{
            alert('Erro: ' + err);
          });
        });
      }
    })
  }
  addNew() {
    this.router.navigate(["Agencies"]);
  }
  edit(agenciesId: number) {
    this.router.navigate(['Agencies/' + agenciesId]);
  }
  delete(agenciesId: number) {
    var _arr = this.records.filter(x=>x.agenciesId == agenciesId);
    if(_arr.length<1){
      alert(`No record found for ID: ${agenciesId}`);
      return;
    }
    if (!confirm(`Are you sure to delete "${_arr[0].agenciesName}" ?`)) return;
    this.db.object(`agency/${_arr[0].appId}`).update({s:2}).then(r=>{

    }).catch(err=>{
      alert('Error: ' + err);
    });
    // for (let i = 0; i < this.dataSource.length; ++i) {
    //   if (this.dataSource[i].agenciesId === agenciesId) {
    //     this.service.deleteAgencies(agenciesId).then((res: any) => {
    //       this.dataSource.splice(i, 1);
    //     })
    //   }
    // }
  }
  menuClick(e) {
    if (e.itemData.agenciesId == "addNew") {
      this.addNew();
    }
  //  else if (e.itemData.agenciesId == "refresh") {
  //     this.loadGrid();
  //   }
    else if (e.itemData.agenciesId == "edit") {
      console.log(this.grid.instance.getSelectedRowsData()[0]);
      var param = this.grid.instance.getSelectedRowsData()[0].agenciesId;
      this.edit(param);
    }
    else if (e.itemData.agenciesId == "delete") {
      var a = this.grid.instance.getSelectedRowsData()[0].agenciesId;
      this.delete(a);
    }
  }
  Rowdblclick() {
    var param = this.grid.instance.getSelectedRowsData()[0].agenciesId;
    this.edit(param);
  }
  onContentReady(e){
    this.grid.instance.selectRowsByIndexes([0]);
  }
  Rowclick() {
    this.rowSelected = true;
    var agenciesId = this.grid.instance.getSelectedRowsData()[0].agenciesId;
    var _arr = this.records.filter(s=>s.agenciesId == agenciesId);
    if(_arr.length<1){
      alert(`Agency ${agenciesId} not found!`);
      return;
    }
    this.brouchurelist = _arr[0].brouchureList;
    // var agenciesId= this.grid.instance.getSelectedRowsData()[0].agenciesId;
    // this.service.getBrouchureByAgencies(agenciesId).then((res: any) => {
    //     this.brouchurelist = res.brouchureList;
    // })
  }
}
export interface BrouchureList{
  brouchureDocument: string;
  languageName: string;
  brouchureTitle: string;   
}
export interface Agencies{
  agenciesId:number;
  agenciesName:string;
  agenciesDescription:string;
  brouchureList:BrouchureList[];
  isActive:boolean;
}
