import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { UserService } from '../user.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  menus: any[];
  dataSource: any;
  popupVisible = false;
  formData: User;
  ConfirmPassword: string;
  isLoading: boolean = false; records = [];
  employeeId:number;
  @ViewChild("grid") grid: DxDataGridComponent;
  constructor(
    public service: UserService, public db: AngularFireDatabase, private svcAku: SvcAku,
    private router: Router
  ) {
    this.menus = [
      { userName: "addNew", name: "Add New", icon: "add", location: "after" },
      { userName: "edit", name: "Edit", icon: "edit", location: "after" },
      // { userName: "refresh", name: "Refresh", icon: "refresh", location: "after" }, 
      { employeeId: "delete", name: "Delete", icon: "trash", location: "after" },
      { userName: "resetpassword", name: "Reset-Password", icon: "revert", location: "after" }];
  }

  ngOnInit(): void {
    this.formData = {
      EmployeeId: 0,
      EmployeeName: '',
      UserName: '',
      OldPassword: '',
      Password: '',
      ConfirmPassword: '',
      IsActive: true,
    }
    this.loadGrid();
    this.svcAku.log('P-USER-LIST');
  }

  resetpassword() {
    if (this.dataValidation()) {
      this.back();
      // this.service.ResetPassword(this.formData).then((res: any) => {
      //   alert(res.message);
      //   this.isLoading = false;
      //   if (res.status == true)
      //     this.back();
      // })
    }
  }
  back() {
    this.router.navigate(["Home"]);
  }
  dataValidation() {
    if (this.formData.Password == this.formData.ConfirmPassword) {
      return true;
    }
    else {
      alert("Password and Confirm password not match");
    }
  }
  loadGrid() {
    this.db.list('store').snapshotChanges().subscribe(resStore=>{
      var _stores = this.svcAku.fireRecords(resStore).filter(s=>s.s==1);
      this.db.list('users').snapshotChanges().subscribe(res=>{
        this.records = this.svcAku.fireRecords(res).filter(u=>u.s==1);
        var _userList = [];
        this.records.forEach(r=>{
          var _userStores = _stores.filter(s=>s.id == r.storeId);
          var _userStore = '';
          if(_userStores.length>0) _userStore = _userStores[0].n;
          _userList.push({
            employeeId: r.id,
            userName: r.n,
            employeeName: r.en,
            storeName: _userStore,
            isAdmin: r.a == 1
          });
        });
        this.dataSource = _userList;
        //console.log(_userList, this.records);
      });
    });
    // this.service.getGetUserList().then((res: any) => {
    //   if (res.status == true) {
    //     this.dataSource = res.userList;
    //   }
    // })
  }
  addNew() {
    this.router.navigate(["User"]);
  }
  edit(username:string) {
    this.router.navigate(['User/' + username]);
  }
  delete(employeeId: number) {
    if ((confirm("Are you sure to delete "))) {
      console.log(employeeId);
      var _arr = this.records.filter(r=>r.id == employeeId);
      if(_arr.length<1){
        alert('Record not found!');
        return;
      }
      var _rec = _arr[0];
      this.db.object(`users/${_rec.appId}`).update({s:2}).then(()=>{

      }).catch(err=>{
        alert('Delete failed!');
      });
      // for (let i = 0; i < this.dataSource.length; ++i) {
      //   if (this.dataSource[i].employeeId=== employeeId) {
      //     // this.service.deleteDelete(employeeId).then((res: any) => {
      //     //   this.dataSource.splice(i, 1);
      //     // })
      //   }
      // }
    }
  }
  menuClick(e) {
    if (e.itemData.userName == "addNew") {
      this.addNew();
    }
    else if (e.itemData.userName == "refresh") {
      this.loadGrid();
    }
    else if (e.itemData.userName == "resetpassword") {
      console.log(this.grid.instance.getSelectedRowsData()[0]);
      var a = this.grid.instance.getSelectedRowsData()[0].userName;
      this.resetPassword(a);
    }
    else if (e.itemData.userName == "edit") {
      console.log(this.grid.instance.getSelectedRowsData()[0]);
      var param = this.grid.instance.getSelectedRowsData()[0].userName;
      this.edit(param);
    }
     else if (e.itemData.employeeId == "delete") {
      var a = this.grid.instance.getSelectedRowsData()[0].employeeId;
      this.delete(a);
    }
  }

  resetPassword(id) {
    this.popupVisible = true;
  }

  Rowdblclick() {
    var param = this.grid.instance.getSelectedRowsData()[0].userName;
    this.edit(param);
  }
  onContentReady(e) {
    this.grid.instance.selectRowsByIndexes([0]);
  }
}


export class User {
  EmployeeId: number;
  EmployeeName: string;
  UserName: string;
  Password: string;
  OldPassword: string;
  ConfirmPassword: string;
  IsActive: boolean; 
}
