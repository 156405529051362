
<div class="top-menu">
    <dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
    </dx-menu>
</div>
  
    
    
    <div class="content-block dx-card responsive-paddings">
    
        <dx-form #form agenciesId="form" [colCount]="3" [(formData)]="formData">
          <dxi-item itemType="group" [colSpan]=3 [colCount]="1" caption="Agency">
            <dxi-item dataField="agenciesId"  [visible]=false></dxi-item>
            
            <dxi-item dataField="agenciesName"   [label]="{text: ('Agency Name')}" [editorOptions]="{ maxLength: '50'}" >
              <dxi-validation-rule type="required" message="Agencies Name  is required">
              </dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="50" message="Agencies Name  length must be less than 50" >
              </dxi-validation-rule>
      
            </dxi-item>

            <dxi-item dataField="agenciesDescription" [label]="{text: ('Agency Description')}" [editorOptions]="{ maxLength: '250'}">
                <dxi-validation-rule type="stringLength" [max]="250" message="Agency Description length must be less than 250">
                </dxi-validation-rule>
              </dxi-item> 
             </dxi-item>
             <!-- <dxi-item dataField="isActive" editorType="dxCheckBox"></dxi-item> -->
          
          
        </dx-form>
      </div>
    

