import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss']
})
export class ConfigsComponent implements OnInit {
  @ViewChild("form", { static: false }) form: DxFormComponent
  menus: any[];
  formData: SiteConfig;
  ConfirmPassword: string;
  isLoading: boolean = false;

  constructor(
    //public service: UserService,
    private router: Router, public db: AngularFireDatabase, private svcAku: SvcAku
  ) {
    this.menus = [{ userName: "save", name: "Save", icon: "save", location: "after" }];
  }

  ngOnInit(): void {
    this.formData = {
      showLanguageOptions: false, showBrochures: false, showVideos: false,
      backgroundColor: '',
      bannerColor: '',
      footerColor: '',
      titleColor: ''
    };
    this.dataLoad();
  }
  dataLoad(){
    this.isLoading = true;
    this.db.list('configs').snapshotChanges().subscribe(res=>{
      this.isLoading = false;
      var _recList = this.svcAku.fireRecords(res);
      
      _recList.forEach(r=>{
        if(r.appId == 'bg') this.formData.backgroundColor = r.color;
        if(r.appId == 'banner') this.formData.bannerColor = r.color;
        if(r.appId == 'footer') this.formData.footerColor = r.color;
        if(r.appId == 'title') this.formData.titleColor = r.color;
        if(r.appId == 'lang') this.formData.showLanguageOptions = r.show == 1;
        if(r.appId == 'brochures') this.formData.showBrochures = r.show == 1;
        if(r.appId == 'videos') this.formData.showVideos = r.show == 1;
      });
    });
  }
  change() {
    if (!this.dataValidation()) return;
    this.db.object('configs/bg').set({color: this.formData.backgroundColor}).then(resBg=>{
      this.db.object('configs/banner').set({color: this.formData.bannerColor}).then(resBanner=>{
        this.db.object('configs/footer').set({color: this.formData.footerColor}).then(resFooter=>{
          this.db.object('configs/title').set({color: this.formData.titleColor}).then(resTitle=>{
            this.db.object('configs/brochures').set({show: this.formData.showBrochures?1:0}).then(resBrochures=>{
              this.db.object('configs/videos').set({show: this.formData.showVideos?1:0}).then(resVideos=>{
                this.db.object('configs/lang').set({show: this.formData.showLanguageOptions?1:0}).then(resLang=>{
                  alert('Configs are successfully updated!');
                  this.svcAku.log('A-CONFIGS-UPDATE');
                })
                .catch(errLang=>{
                  alert('Update failed for Show Language Options!');
                });
              });
            });
          });
        });
      });
    })
    .catch(errBg=>{
      alert('Update failed for Background Color!');
      return;
    });
  }
  back() {
    this.router.navigate(["/home"]);
  }
  dataValidation() {
    return true;
    // if (this.formData.Password == this.formData.ConfirmPassword) {
    //   return true;
    // }
    // else{
    //   alert("Password and Confirm password not match");
    // }
  }
  menuClick(e) {
    if (e.itemData.userName == "save") {
      console.log(this.formData);
      this.change();
    }
    else if (e.itemData.userName == "back") {
      this.back();
    }
  }
}


export class Users{
  EmployeeId: number;
    EmployeeName: string;
    UserName: string;
    Password: string;
    ConfirmPassword:string;
    OldPassword:string;
    IsActive: boolean;
  }
export class SiteConfig{
  showLanguageOptions: boolean;
  showBrochures: boolean;
  showVideos: boolean;
  backgroundColor: string;
  bannerColor: string;
  footerColor: string;
  titleColor: string;
}