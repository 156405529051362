
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { AppMain } from 'src/app/app.main';
import { VideoService } from '../video.service';
import { map ,catchError} from 'rxjs/operators';
import { throwError } from "rxjs";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { LanguageService } from '../../language/language.service';
import { StoreService } from '../../store/store.service';
import validationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  value: any[] = [];
  menus: any[];
  fileToUpload: File = null;
  imgURL: string = '';// this.NullImage;
  mobileImgURL: string = '';// this.NullImage;
  thumbBase64:string='';
  documentBase64:string=''; docB64 = ''; docFileName = '';
  hasImageChanged: boolean = false;
  useButtons: string;
  formData: video;
  videoId: number = 0;
  iconvisible: boolean = false;
  progress: number;
  videoStores:videostore[]=[];
  Languages: string[];
  Stores = []; records = [];
  fileToDocument: File = null;
  uploadAPI:string="";

  videoURL:string=""; recEditId = '';
  videoFilePath = '';
  videoFileName = '';

  @ViewChild("form", { static: false }) form: DxFormComponent


  constructor(
    private router: Router,
    private  appMain:AppMain,
    private route: ActivatedRoute,
    private service:VideoService,
    private serviceLanguage: LanguageService,
    private serviceStore: StoreService, private svcAku: SvcAku,
    public db: AngularFireDatabase, public afStore: AngularFireStorage,
    private http: HttpClient
  )  {
    this.menus = [
      { videoId: "save", name: "Save", icon: "save", location: "after" },
      { videoId: "back", name: "Back", icon: "back", location: "after" }
    ];
  }

  ngOnInit(): void {
    this.uploadAPI = this.appMain.apiURL+"FileUploads";
    this.videoURL = this.appMain.fileURL+"Images/Video/";

    this.formData = { languageId: 0, videoId: 0, videoTitle: "",videoThumbnail:"",
    thumbBase64:"",videoDocument:"",documentBase64:"",isActive:true, youtubeVideoLink:"", videoStore:[] }

    // this.serviceLanguage.getCboLanguage().then((res: any) => {
    //   this.Languages = res.language;
    // });
    // this.serviceStore.getCboStore().then((res: any) => {
    //   this.Stores = res.store;
    // });
    this.db.list('store').snapshotChanges().subscribe(resStore=>{
      var _storeList = this.svcAku.fireRecords(resStore);
      this.Stores = [];
      _storeList.forEach(r=>{
        if(r.s != 1) return;
        this.Stores.push({
          storeId: r.id, storeName: r.n
        })
      });
      this.videoId = Number(this.route.snapshot.paramMap.get('videoId'));
      this.db.list('videos').snapshotChanges().subscribe(res=>{
        this.records = this.svcAku.fireRecords(res);
        if (this.videoId == 0) // add new mode
        { }
        else {
          var _arr = this.records.filter(x=>x.id == this.videoId);
          if(_arr.length<1){
            alert('Record not found! ' + this.videoId);
            return;
          }
          //console.log('edit', _arr[0]);
          var _record = _arr[0];
          this.recEditId = _record.appId;
          this.videoFilePath = _record.url;
          this.videoFileName = _record.fn;
          this.formData.isActive = _record.s == 1;
          this.formData.videoTitle = _record.n;
          var _videoStoreList = [];
          if(_record.store && _record.store.length>0){
            _record.store.forEach(s => {
              _videoStoreList.push({storeId: s.id, videoId: 0});
            });
          }
          this.formData.videoStore = _videoStoreList;
          // update mode
          // this.service.getVideoByVideoId(this.videoId).then((res: any) => {
          //   this.formData = res.video;
          //   this.formData.videoStore = res.store;
          this.imgURL="xxx";
          this.mobileImgURL="x2";
          // })
        }
      });
    });
  }
  clearImage() {
    this.imgURL = "";
    this.iconvisible = false;
  }
  loadImage(e) {
    if (e.value.length) {
      this.fileToUpload = e.value[0];
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.thumbBase64 = event.target.result;
        this.hasImageChanged = true;
        this.iconvisible = false;
        this.imgURL= event.target.result;
      }
      reader.readAsDataURL(this.fileToUpload);
    }
    else
      this.imgURL = "";
    this.hasImageChanged = true;
  }
  loadDocumentImage(e) {
    if (e.value.length) {
      this.fileToDocument = e.value[0];
      this.docFileName = this.fileToDocument.name;
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.documentBase64 = event.target.result;
        var _arr = this.documentBase64.split(',');
        this.docB64 = _arr.length>0 ? _arr[1] : _arr[0];
        // this.hasImageChanged = true;
        // this.iconvisible = false;
        //this.documentImgURL= event.target.result;
      }
      reader.readAsDataURL(this.fileToDocument);
    // if (e.value.length) {
    //   this.fileToUpload = e.value[0];
    //   var reader = new FileReader();
    //   reader.onload = (event: any) => {
    //     this.documentBase64 = event.target.result;
    //     this.hasImageChanged = true;
    //     this.iconvisible = false;
    //     this.documentImgURL= event.target.result;
    //   }
    //   reader.readAsDataURL(this.fileToUpload);
      }
    // else this.imgURL = "";
    // this.hasImageChanged = true;
  }
  onUploaded(e){
    console.log(e);
  }
  dataValidation(): boolean {
    for (var i = 0; i <= this.formData.videoStore.length - 1; i++)
    this.formData.videoStore[i].videoId = i;
    return true;
  }
  save() {
    // validation check here
    this.dataValidation();
    this.formData.thumbBase64=this.thumbBase64;
    if (this.fileToDocument != null)
    this.formData.videoDocument = this.fileToDocument.name;

    var result = this.form.instance.validate();
    if (result.isValid) {
      
      if(this.formData.isActive ==null) this.formData.isActive=false;

      var _recStores = [];
      this.formData.videoStore.forEach(a=>{
        _recStores.push({id: a.storeId, s: 1});
      });

      if (this.videoId == 0) { // add new
        if(!this.docB64 || this.docB64.length<1){
          alert("Please upload the video file!");
          return;
        }
        var _newId = 1;
        var _firstRecId = this.records[0] ? this.records[0].id : 0; 
        var _recMax = this.records.reduce((max, r)=> (r.id > max ? r.id:max), _firstRecId);
        if(_recMax) _newId = _recMax+1;
        var _recId = this.svcAku.stringAppId('VDO');
        const refStore = this.afStore.ref(`/videos/${_recId}/${this.formData.videoDocument}`);
        refStore.putString(this.docB64, 'base64', {contentType: 'video/mp4'}).then((resUpload:any)=>{
          console.log(`uploaded:`, resUpload);
        }).then(()=>{
          refStore.getDownloadURL().subscribe(resUrl=>{
            this.db.object(`videos/${_recId}`).set({
              id: _newId, s: this.formData.isActive ? 1 : 0, n: this.formData.videoTitle, fn: this.docFileName, url: resUrl, store: _recStores
            }).then(()=>{
              this.back();
            });
          });
        });
        // this.service.saveVideo(this.formData).then((res: any) => {
        //   alert(res.message);
        //   if (res.status == true)
        //       this.back();
        // })
      } else { // udpate
        if(!this.docB64 || this.docB64.length<1){
          this.db.object(`videos/${this.recEditId}`).update({
            s: this.formData.isActive ? 1 : 0, n: this.formData.videoTitle, store: _recStores
          }).then(()=>{
            this.back();
          });
        }
        else{
          const refStore = this.afStore.ref(`/videos/${this.recEditId}/${this.formData.videoDocument}`);
          refStore.putString(this.docB64, 'base64', {contentType: 'video/mp4'}).then((resUpload:any)=>{
            console.log(`uploaded:`, resUpload);
          }).then(()=>{
            refStore.getDownloadURL().subscribe(resUrl=>{
              this.db.object(`videos/${this.recEditId}`).update({
                s: this.formData.isActive ? 1 : 0, n: this.formData.videoTitle, fn: this.docFileName, url: resUrl, store: _recStores
              }).then(()=>{
                this.back();
              });
            });
          });
        }
        // this.service.updateVideo(this.videoId, this.formData).then((res: any) => {
        //   alert(res.message);
        //   if (res.status == true)
        //     this.back();
        // })
      }
    }
  }
  menuClick(e) {
    if (e.itemData.videoId == "save") {
      console.log(this.formData);
      this.save();
    }
    else if (e.itemData.videoId == "back") {
      this.back();
    }
  }
  back() {
    this.router.navigate(["/VideoList"]);
  }
}

export interface videostore {
  videoId: number;
  storeId: number;
}
  
export class video {
  videoId: number;
  videoTitle: string;
  languageId: number;
  videoThumbnail:string;
  thumbBase64:string;
  videoDocument:string;
  documentBase64:string;
  isActive: boolean;
  videoStore:videostore[];
  youtubeVideoLink:string;
}
