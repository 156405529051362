import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import * as firebase from 'firebase';

@Injectable()
export abstract class AbsSvcAuth {
  user:any;

  abstract login(email: string, password: string);
  abstract userGet();
  abstract userSet(arg:any);
}

@Injectable({ providedIn: 'root' })
export class SvcAuth implements AbsSvcAuth{
  constructor(
    private http: HttpClient,
    private fireAuth: AngularFireAuth
  ){
    this.user = fireAuth.authState;
  }
  user: Observable<firebase.User>;

  login(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.fireAuth.auth.signInWithEmailAndPassword(email, password);
  }
  loginValidate(email: string, password: string): Promise<firebase.auth.UserCredential> {
    var user = this.fireAuth.auth.currentUser;
    var credentials = firebase.auth.EmailAuthProvider.credential(email, password);
    //user.re
    return user.reauthenticateWithCredential(credentials);
  }
  async logout(){
    await this.fireAuth.auth.signOut();
    localStorage.removeItem('app-user');
  }
  // register(name:string, email: string, password: string):Observable<any> {
  //   var config = {apiKey: "apiKey",
  //   authDomain: "projectId.firebaseapp.com",
  //   databaseURL: "https://databaseName.firebaseio.com"};
  //   var secondaryApp = firebase.initializeApp(config, "Secondary");

  //   return secondaryApp.auth().createUserWithEmailAndPassword(email, password);
  // }
  register(name:string, email: string, password: string):Observable<any> {
    // return this.http.post(`https://us-central1-wst-psc.cloudfunctions.net/userAdd`, { 
      return this.http.post(`https://us-central1-wst-ebrouchure-demo-7efe4.cloudfunctions.net/userAdd`, { 
      email: email,
      emailVerified: false,
      phoneNumber: '+95973026733',
      password: password,
      name: name,
      disabled: false
    });
  }
  updateUser(userId:string, name:string, email: string, password: string):Observable<any> {
    return this.http.post(`https://us-central1-wst-psc.cloudfunctions.net/userUpdate`, {
      userId: userId, 
      email: email,
      emailVerified: false,
      phoneNumber: '+95973026733',
      password: password,
      name: name,
      disabled: false
    });
  }
  registerV01(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.fireAuth.auth.createUserWithEmailAndPassword(email, password);
  }
  userGet(){
    this.user.subscribe(res=>{
      return res;
    });
  }
  userSet(argUser){
    this.user = of(argUser);
    localStorage.setItem('app-user', JSON.stringify(argUser));
  }
  isMgr(){
    var _level = this.levelGet();
    return _level == 'MGR';
  }
  accInfoGet():any{
    var _str = this.localStorageGet('accInfo');
    return JSON.parse(_str);
  }
  accInfoSet(argAccountInfo):any{
    return this.localStorageSet('accInfo', argAccountInfo);
  }
  levelGet():string{
    return this.localStorageGet('level');
  }
  levelSet(level){
    return this.localStorageSet('level', level);
  }
  roleGet(argUser):string{
    return argUser.displayName;
  }
  gidGet():string{
    return this.localStorageGet('gid');
  }
  gidSet(gid){
    this.localStorageSet('gid', gid);
  }
  gnameGet(){
    return this.localStorageGet('gname');
  }
  gnameSet(gname){
    this.localStorageSet('gname', gname);
  }
  localStorageGet(_key):string{
    return localStorage.getItem(_key);
  }
  localStorageSet(_key, _value){
    localStorage.setItem(_key, _value);
  }
}