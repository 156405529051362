import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMain } from 'src/app/app.main';
import { Users } from './Changepassword/changepassword/changepassword.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private appMain: AppMain
  ) { }

  public getGetUserList() {
    return this.http.get(this.appMain.apiURL +"GetUserList").toPromise();
  }
  public getGetUserDetailsByLoginName(userName: string) {
    return this.http.get(this.appMain.apiURL + "GetUserDetails/"+userName).toPromise();
  }
  public saveconfig_user(formData: any) {
    return this.http.post(this.appMain.apiURL + "config_user", formData).toPromise();
  }
  public updateconfig_user(userName: string,formData: any) {
    return this.http.put(this.appMain.apiURL + "config_user/"+userName, formData).toPromise();
  }
    public deleteDelete(employeeId: number){
    return this.http.delete(this.appMain.apiURL + "Delete/" + employeeId).toPromise();
  }
  public ChangePassword(data: Users, userName:string) {
    return this.http.put(this.appMain.apiURL + "changePassword/2/"+userName, data).toPromise();
  }
  public ResetPassword(data: Users) {
    return this.http.put(this.appMain.apiURL + "ResetPassword/2", data).toPromise();
  }
 
}
