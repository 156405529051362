import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMain } from 'src/app/app.main';

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {

    
  constructor(
    private http: HttpClient,
    private appMain: AppMain
  ) { }

  public getAgencies() {
    return this.http.get(this.appMain.apiURL +"Agencies").toPromise();
  }
  public getAgenciesByAgenciesId(agenciesId:number) {
    return this.http.get(this.appMain.apiURL + "Agencies/"+agenciesId).toPromise();
  }
  public saveAgencies(formData: any) {
    return this.http.post(this.appMain.apiURL + "Agencies", formData).toPromise();
  }
  public updateAgenciese(agenciesId:number,formData: any) {
    return this.http.put(this.appMain.apiURL + "Agenciese/"+agenciesId, formData).toPromise();
  }
  public deleteAgencies(agenciesId: number){
    return this.http.delete(this.appMain.apiURL + "Agencies/" + agenciesId).toPromise();
  }
  public getCboAgencies() {
    return this.http.get(this.appMain.apiURL +"CboAgencies").toPromise();
  }
  public getBrouchureByAgencies(agenciesId: number){
    return this.http.get(this.appMain.apiURL + "BrouchureByAgencies/"+ agenciesId).toPromise();

  }
}
