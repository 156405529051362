export const navigation = [
  // {
  //   text: 'Home',
  //   path: '/home',
  //   icon: 'home'
  // },
  // {
  //   text: 'Store',
  //   icon: 'product',
  //   path: '/Storelist'
  // },
  {
    text: 'User',
    icon: 'user',
    path: '/UserList'
  },
  {
    text: 'Agency',
    icon: 'group',
    path: '/AgenciesList'
  },
  {
    text: 'Brochure',
    icon: 'image',
    path: '/Brouchurelist'
  },
  {
    text: 'Video',
    icon: 'video',
    path: '/VideoList'
  },
  {
    text: 'Activity Logs',
    icon: 'clock',
    path: '/activitylogs'
  },
  {
    text: 'Configs',
    icon: 'preferences',
    path: '/configs'
  },
  {
    text: 'Change-Password',
    icon: 'key',
    path: '/Changepassword'
  },
 {
    text: 'Logout',
    icon: 'runner',
    path: '/Logout'
    },




    
  // {
  //   text: 'Language',
  //   icon: 'columnfield',
  //   path: '/Languagelist'
  // },

  // {
  //   text: 'User',
  //   icon: 'user',
  //   path: '/'
  // },

  // {
  //   text: 'Homeslider',
  //   icon: 'product',
  //   path: '/homesliderList'
  // },

  // {
  //   text: 'System',
  //   icon: 'pinright',
  //   items: [
  //     {
  //       text: 'Settings',
  //       icon: 'preferences',
  //       items: [
  //         {
  //           text: 'General',
  //           icon: 'tableproperties',
  //           path: ''
  //         },
  //         {
  //           text: 'Email',
  //           icon: 'email',
  //           path: ''
  //         }
  //       ]
  //     }


];

export const navigationnonadm = [
  {
    text: 'Agency',
    icon: 'group',
    path: '/AgenciesList'
  },
  {
    text: 'Brochure',
    icon: 'image',
    path: '/Brouchurelist'
  },
  {
    text: 'Video',
    icon: 'video',
    path: '/VideoList'
  },
  {
    text: 'Activity Logs',
    icon: 'clock',
    path: '/activitylogs'
  },
  {
    text: 'Configs',
    icon: 'preferences',
    path: '/configs'
  },
  {
    text: 'Change-Password',
    icon: 'key',
    path: '/Changepassword'
  },
  {
    text: 'Logout',
    icon: 'runner',
    path: '/Logout'
  },
];


