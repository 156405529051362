import { Injectable } from "@angular/core";
import * as _moment from 'moment';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({ providedIn: 'root' })
export class SvcAku{
	constructor(private db: AngularFireDatabase){}

	fireRecords(res){
    var rtn = [];
    res.forEach(r => {
      var _rec:any = r.payload.val();
      _rec.appId = r.key;
      rtn.push(_rec);
    });
    return rtn;
  }
  stringAppId(code):string{
    var _prefix = this.stringRandom(3).toUpperCase();
    var _suffix = this.stringRandom(3).toUpperCase();
    return `${code}-${_moment.utc().format("YYYYMMDDHHmmss")}${_prefix}${_suffix}`;
  }
  stringRandom(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  log(_code, _desc=''){
    var _momentNowUtc = _moment().utc();
    var _dayPath = `${_momentNowUtc.year()}/${_momentNowUtc.month()+1}/${_momentNowUtc.date()}/a`;
    var _timeCode = _momentNowUtc.format('HHmmssSSS');//this.stringAppId('L');
    var _userId = localStorage.getItem("boUserId");
    if(!_userId) return;
    var _log:any = {
      //t: _momentNowUtc.format('YYYYMMDDHHmmss'),
      c: _code,
      //d: _desc
    };
    if(_desc != '') _log.d = _desc;
    this.db.object(`logs/${_dayPath}/${_userId}/${_timeCode}`).set(_log);
  }

  logsData(res){
    var rtn = [];
    res.forEach(r => {
      var _rec:any = r.payload.exists("VIEW-BROCHURE");
      _rec.appId = r.key;
      rtn.push(_rec);
    });
    return rtn;
  }
}
