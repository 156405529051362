import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { AgenciesService } from '../agencies.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.scss']
})
export class AgenciesComponent implements OnInit {
  menus: any[];
  formData: Agencies;
  agenciesId:number = 0; records = []; recordId = '';
  @ViewChild("form", { static: false }) form: DxFormComponent
  constructor(private router: Router,
    private route: ActivatedRoute, public db: AngularFireDatabase, private svcAku: SvcAku,
    private service: AgenciesService
  ) {
    this.menus = [
      { agenciesId: "save", name: "Save", icon: "save", location: "after" }, 
      { agenciesId: "back", name: "Back", icon: "back", location: "after" }];
  }

  ngOnInit(): void {
    this.formData = {agenciesId:0,agenciesName:"",agenciesDescription:"",isActive:true }
    this.agenciesId = Number(this.route.snapshot.paramMap.get('agenciesId'));
    this.db.list('agency').snapshotChanges().subscribe(res=>{
      var _resList = this.svcAku.fireRecords(res);
      this.records = [];
      _resList.forEach(r=>{
        this.records.push({ agenciesId: r.id, agenciesName: r.n, agenciesDescription: r.d, isActive: true, appId: r.appId });
      });
      if (this.agenciesId == 0) // New mode
      { }
      else {  // Update mode
        var _arr = this.records.filter(x=>x.agenciesId == this.agenciesId);
        if(_arr.length>0){
          this.formData = _arr[0];
          this.recordId = _arr[0].appId;
        }
        // this.service.getAgenciesByAgenciesId(this.agenciesId).then((res: any) => {
        //   this.formData = res.agencies;
        // })
      }
    });
  }
  back() {
    this.router.navigate(["AgenciesList"]);
  }
  save() {
    // validation check here
    var result = this.form.instance.validate();
    if (result.isValid) {
      if(this.formData.isActive == null) this.formData.isActive=false;
      if (this.agenciesId == 0) { // add new
        var _newId = 1;
        var _recMax = this.records.reduce((max, r)=> (r.agenciesId > max ? r.agenciesId:max), this.records[0].agenciesId);
        if(_recMax) _newId = _recMax+1;
        var _recId = this.svcAku.stringAppId('A');
        this.db.object(`agency/${_recId}`).set({
          id: _newId,
          n: this.formData.agenciesName,
          d: this.formData.agenciesDescription,
          s: 1,
          sId: 2
        }).then(r=>{
          this.back();
        }).catch(err=>{
          alert('Erro: ' + err);
        });
        // this.service.saveAgencies(this.formData).then((res: any) => {
        //   alert(res.message);
        //   if (res.status == true)
        //     this.back();
        // })
      } else { // udpate
        this.service.updateAgenciese(this.agenciesId, this.formData).then((res: any) => {
          alert(res.message);
          if (res.status == true)
            this.back();
        })
      }
    }
  }
  menuClick(e) {
    if (e.itemData.agenciesId == "save") {
      console.log(this.formData);
      this.save();
    }
    else if (e.itemData.agenciesId == "back") {
      this.back();
    }
  }
}
export class Agencies{
  agenciesId:number;
  agenciesName:string;
  agenciesDescription:string;
  isActive: boolean;
}
