import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, ResetPasswordFormComponent, CreateAccountFormComponent, ChangePasswordFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { DxDataGridModule, DxDateBoxModule, DxFormModule } from 'devextreme-angular';
import { UserComponent } from './pages/user/user/user.component';
import { UserlistComponent } from './pages/user/userlist/userlist.component';
import { AuthGuard } from './auth/auth.guard';
import { ChangepasswordComponent } from './pages/user/Changepassword/changepassword/changepassword.component';
import { LanguageComponent } from './pages/language/language/language.component';
import { LanguagelistComponent } from './pages/language/languagelist/languagelist.component';
import { StoreComponent } from './pages/store/store/store.component';
import { StorelistComponent } from './pages/store/storelist/storelist.component';
import { BrouchureComponent } from './pages/brouchure/brouchure/brouchure.component';
import { BrouchurelistComponent } from './pages/brouchure/brouchurelist/brouchurelist.component';
import { VideoComponent } from './pages/video/video/video.component';
import { VideolistComponent } from './pages/video/videolist/videolist.component';
import { AgenciesComponent } from './pages/Agencies/agencies/agencies.component';
import { AgenciesListComponent } from './pages/Agencies/agencies-list/agencies-list.component';
import { LogoutComponent } from './Logout/logout/logout.component';
import { ConfigsComponent } from './pages/configs/configs.component';
import { ActivitylogsComponent } from './pages/activitylogs/activitylogs.component';


const routes: Routes = [

  /* {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login-form',
    component: LoginComponent
  },
  {
    path: '', component: DefaultLayoutComponent,
    children: [ */
      {
        path: 'tasks',
        component: TasksComponent,
       canActivate: [AuthGuardService]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: '',
        component: StorelistComponent ,
        canActivate: [AuthGuard]
      },
      {
        path: 'home',
        component: HomeComponent ,
        canActivate: [AuthGuard]
      },
    
      {
        path:'login-form',
        component:LoginFormComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordFormComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'create-account',
        component: CreateAccountFormComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'change-password/:recoveryCode',
        component: ChangePasswordFormComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'activitylogs',
        component: ActivitylogsComponent,canActivate: [AuthGuard]
      },
      {
        path: 'configs',
        component: ConfigsComponent,canActivate: [AuthGuard]
      },
      {
        path: 'Changepassword',
        component: ChangepasswordComponent,canActivate: [AuthGuard]
      },
     {
        path: 'User',
        component: UserComponent,canActivate: [AuthGuard]
      },
      {
        path: 'User/:userName',
        component: UserComponent,canActivate: [AuthGuard]
      },

      {
        path: 'UserList',
        component: UserlistComponent,canActivate: [AuthGuard]
      },
      
      {
        path: 'Language',
        component: LanguageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Language/:languageId',
        component: LanguageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Languagelist',
        component: LanguagelistComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Video',
        component: VideoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Video/:videoId',
        component: VideoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'VideoList',
        component: VideolistComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Agencie',
        component: AgenciesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Agencie/:AgencieId',
        component: AgenciesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'AgencieList',
        component: AgenciesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Store',
        component: StoreComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Store/:storeId',
        component: StoreComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Storelist',
        component: StorelistComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Brouchure',
        component: BrouchureComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Brouchure/:brouchureId',
        component: BrouchureComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Brouchurelist',
        component: BrouchurelistComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Agencies',
        component: AgenciesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Agencies/:agenciesId',
        component: AgenciesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'AgenciesList',
        component: AgenciesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Logout',
        component: LogoutComponent,
        canActivate: [AuthGuard]
      },
    

      // {
      //   path: '**',
      //   redirectTo: 'home',canActivate: [AuthGuard]
      // }
    /* ]
  } */

];

@NgModule({
  imports: [RouterModule.forRoot(routes), DxDataGridModule, DxFormModule, DxDateBoxModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [HomeComponent, ProfileComponent, TasksComponent]
})
export class AppRoutingModule { }
