import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';
import { AppMain } from './app.main';
import { DxDataGridModule, DxFormModule, DxMenuModule, DxToolbarModule, DxListModule, DxButtonModule, DxTabPanelModule, DxFileUploaderModule, DxSelectBoxModule, DxLookupModule, DxHtmlEditorModule, DxCheckBoxModule, DxTextBoxModule, DxPopupModule, DxChartModule, DxDateBoxModule } from 'devextreme-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { DxNumberBoxModule } from 'devextreme-angular';
import { UserComponent } from './pages/user/user/user.component';
import { UserlistComponent } from './pages/user/userlist/userlist.component';
import { LoginComponent } from './pages/login/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { ChangepasswordComponent } from './pages/user/Changepassword/changepassword/changepassword.component';
import { ConfigsComponent } from './pages/configs/configs.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword/resetpassword.component';
import { BrouchureComponent } from './pages/brouchure/brouchure/brouchure.component';
import { BrouchurelistComponent } from './pages/brouchure/brouchurelist/brouchurelist.component';
import { LanguageComponent } from './pages/language/language/language.component';
import { LanguagelistComponent } from './pages/language/languagelist/languagelist.component';
import { StoreComponent } from './pages/store/store/store.component';
import { StorelistComponent } from './pages/store/storelist/storelist.component';
import { VideoComponent } from './pages/video/video/video.component';
import { VideolistComponent } from './pages/video/videolist/videolist.component';
import { AgenciesComponent } from './pages/Agencies/agencies/agencies.component';
import { AgenciesListComponent } from './pages/Agencies/agencies-list/agencies-list.component';
import { LogoutComponent } from './Logout/logout/logout.component';
import { ActivitylogsComponent } from './pages/activitylogs/activitylogs.component';

export function init_app(app: AppMain) {
  return () => app.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    UserlistComponent,
    LoginComponent,
    ChangepasswordComponent, ConfigsComponent,ActivitylogsComponent,
    ResetpasswordComponent,
    BrouchureComponent,
    BrouchurelistComponent,
    LanguageComponent,
    LanguagelistComponent,
    StoreComponent,
    StorelistComponent,
    VideoComponent,
    VideolistComponent,
    AgenciesComponent,
    AgenciesListComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    FormsModule,
    LoginFormModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    DxDataGridModule, DxFormModule, DxMenuModule,DxLookupModule,
    DxToolbarModule, DxListModule,DxButtonModule,DxTabPanelModule,
    DxFileUploaderModule, DxNumberBoxModule,DxSelectBoxModule,  DxHtmlEditorModule,
    DxCheckBoxModule,DxChartModule,DxDateBoxModule,
    LoadingBarHttpClientModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopupModule,
    AngularFireModule.initializeApp(environment.firebase), AngularFireDatabaseModule, AngularFirestoreModule, AngularFireAuthModule, AngularFireStorageModule
  ],
  providers: [AppMain,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppMain], multi: true },
    AuthService, ScreenService, AppInfoService ,AuthGuard, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
    
  bootstrap: [AppComponent]
})
export class AppModule { }
