<div class="content-block dx-card responsive-paddings">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">From Date</div>
      <div class="dx-field-value">
        <dx-date-box [(ngModel)]="selectedMomentStart" [value]="selectedMomentStart" type="date"> </dx-date-box>
      </div>
    </div>
  </div>
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">To Date</div>
      <div class="dx-field-value">
        <dx-date-box [(ngModel)]="selectedMomentEnd" [value]="selectedMomentEnd" type="date"> </dx-date-box>
      </div>
    </div>
  </div>
  <dx-button style="float: right;" stylingMode="outlined" text="Search"  type="normal" [width]="120" (onClick)="Search()">
  </dx-button>
<dx-chart
  id="chart"
  title='Activity Logs'
  [dataSource]="activityLogsDXSource">  
  <dxo-legend [visible]="false"></dxo-legend>
  <dxi-series type="bar" name="count"></dxi-series>
  <dxo-argument-axis [tickInterval]="10">
    <dxo-label>
      <dxo-format type="number"></dxo-format>
    </dxo-label>
  </dxo-argument-axis>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-tooltip
    [enabled]="true"
    [shared]="true"
    [customizeTooltip]="customizeTooltip">
  
    <dxo-format type="largeNumber" [precision]="0"></dxo-format>
  </dxo-tooltip>
</dx-chart>
</div>

<!-- <div class="container-fluid mt-70">
  <div class="row" style="margin-top: 70px; margin-left: 40px; margin-right: 40px; border-color: black;">    
    <div class="col-xl-6">
      <div class="card shadow">
        <div class="card-body" style="background-color: hsl(288, 85%, 90%);">
          <div class="chart">
            <canvas id="chart-orders" class="chart-canvas"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->