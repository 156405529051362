
<div class="top-menu">
    <dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
    </dx-menu>
</div>
  
    <div class="content-block dx-card responsive-paddings">
        <dx-form #form storeId="form" [colCount]="3" [(formData)]="formData">
          <dxi-item itemType="group"  [colSpan]=3 [colCount]="2" caption="Store">
            <dxi-item dataField="storeId"  [visible]=false></dxi-item>
            <dxi-item dataField="storeName" [editorOptions]="{ maxLength: '50'}">
              <dxi-validation-rule type="required" message="StoreName is required" >
              </dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="50" message="Store Name  length must be less than 50">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="location" [editorOptions]="{ maxLength: '50'}">
              <dxi-validation-rule type="required" message="Location is required" >
              </dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="50" message="Location length must be less than 50">
              </dxi-validation-rule>
            </dxi-item> 
            <dxi-item dataField="contactPerson" [editorOptions]="{ maxLength: '20'}">
              <dxi-validation-rule type="required" message="Contact Person is required" >
              </dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="20" message="Contact Person length must be less than 20">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="contactNumber" ></dxi-item> 
            <dxi-validation-rule type="required" message="Contact Number is required" >
            </dxi-validation-rule>
            <dxi-item dataField="note" [editorOptions]="{ maxLength: '200'}">
              <dxi-validation-rule type="required" message="Note is required" >
              </dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="200" message="Note length must be less than 200">
              </dxi-validation-rule>
            </dxi-item> 
            <!-- <dxi-item dataField="isActive" editorType="dxCheckBox"></dxi-item> -->
             </dxi-item>  
        </dx-form>
      </div>
    

