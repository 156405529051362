import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  menus: any[];
  formData: language;
  languageId:number = 0;
  @ViewChild("form", { static: false }) form: DxFormComponent
  constructor(private router: Router,
    private route: ActivatedRoute,
    private service: LanguageService
  ) {
    this.menus = [
    //   {
    //   languageId: "save",
    //   name: "Save",
    //   icon: "save",
    //   location: "after"

    // }, 
    {
      languageId: "back",
      name: "Back",
      icon: "back",
      location: "after"

    }];
  }

  ngOnInit(): void {

    this.formData = { languageId:0,languageName:"" }

    this.languageId = Number(this.route.snapshot.paramMap.get('languageId'));
    if (this.languageId == 0) // add new mode
    {

    }
    else {
      // update mode
      this.service.getLanguageByLanguageId(this.languageId).then((res: any) => {
        this.formData = res.language;
      })
    }
  }

  back() {
    this.router.navigate(["Languagelist"]);
  }
  save() {
    // validation check here
    var result = this.form.instance.validate();
    if (result.isValid) {
      if (this.languageId == 0) { // add new
        this.service.saveLanguage(this.formData).then((res: any) => {
          alert(res.message);
          if (res.status == true)
            this.back();
        })
      } else { // udpate
        this.service.updateLanguage(this.languageId, this.formData).then((res: any) => {
          alert(res.message);
          if (res.status == true)
            this.back();
        })
      }
    }
  }

  menuClick(e) {
    if (e.itemData.languageId == "save") {
      console.log(this.formData);
      this.save();
    }
    else if (e.itemData.languageId == "back") {
      this.back();
    }
  }
}
export class language{
  languageId:number;
  languageName:string;
}