import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxFormComponent, DxChartComponent } from 'devextreme-angular';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { AngularFireDatabase } from '@angular/fire/database';
import * as moment from 'moment';
import {Chart} from 'chart.js';
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "src/app/shared/variables/chart";
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-activitylogs',
  templateUrl: './activitylogs.component.html',
  styleUrls: ['./activitylogs.component.scss']
})
export class ActivitylogsComponent implements OnInit {
  @ViewChild("form", { static: false }) form: DxFormComponent
  // menus: any[];
  // formData: SiteConfig;
  // ConfirmPassword: string;
  isLoading: boolean = false;
  arrLabel: any[] = [];
  arrData: any[] = [];
  arrLstWeekData: any[] = [];
  activityLogs = [];
  activityLogsData = [];
  activityLogsDataX = [];
  activityLogsDataSource = [];
  activityLogsDXSource = [];
  pipe = new DatePipe('en-US');
  public salesChart;
  selectedMomentStart = new Date(); //('3 5, 2022');
  selectedMomentEnd = new Date(); //new Date('4 21, 2022');
  now: Date = new Date();
  charTitle: any;


  _date = new Date();
  _userid: any;
  _idx: any = 0;
  activityLogsDataSources: any[] = [];
  _usrname: any;
  uusers: any;


  constructor(
    private router: Router, public db: AngularFireDatabase, private svcAku: SvcAku
  ) {
    // this.menus = [{ userName: "save", name: "Save", icon: "save", location: "after" }];
  }

  ngOnInit(): void {
    this.dataLoad();
  }
  dataLoad(){
    this.activityLogsData =[];
    this.activityLogsDataX = [];
    this.isLoading = true;
    this.db.list('logs').snapshotChanges().subscribe(res=>{
      //console.log(res);
      this.isLoading = false;
      var _recList = this.svcAku.fireRecords(res);
      //console.log(_recList);
      this.activityLogs = [];
      _recList.forEach((r, index)=>{
        //console.log(r);
        var appId = r.appId;
        if(Array.isArray(r)){
          r.forEach((j, i) => {
            this.activityLogs.push({ year: appId, month: i, day: '' ,userid: '', username: '', data: r[i] });
          })
        }
        else{
          var result = Object.entries(r);
          result.forEach((j, i) => {
            this.activityLogs.push({ year: appId, month: i, day: '' ,userid: '', username: '', data: result[i] });
          })
          // Object.keys(r).forEach(key => {
          //   console.log(key, r[key]);
          // });
        }

      });

      this.activityLogs.forEach((l, k)=>{
        for (let [key, value] of Object.entries(l.data)) {
          this.activityLogsData.push({ year: l.year, month: l.month, day: key ,userid: '', username: '', data: value })
        }
      })

      this.activityLogsData.forEach((r, x) => {
        for (let [key, value] of Object.entries(r.data)) {
          var _value = value;
          if(key == "u"){
            r.data = _value;
            for (let [key, value] of Object.entries(_value)) {
              var _key = key; 
              var _valuex = value;   
              for (let [key, value] of Object.entries(_valuex)) {
                var _keyx = key; 
                var _valuexx = value;
                for (let [key, value] of Object.entries(_valuexx)) {
                  if(value == "VIEW-BROCHURE"){
                    this.charTitle = 'Activity Logs For' + value;
                    this.activityLogsDataX.push({year: r.year, month: r.month, day: r.day, date:new Date((r.month+' '+ r.day+','+r.year).toString()), time: _keyx ,userid: _key, username: '', data: _valuexx})
                    //console.log(this.activityLogsDataX);
                  }   
                }
              }
            }
          }
        }
      }) 
      //this.BindChart();
      this.Search();
    });
  }

  Search(){
    this.activityLogsDXSource=[];this.activityLogsDataSource=[]; 
    this._date = new Date();this._userid = "";this._idx = 0;
    this.selectedMomentStart.setHours(0,0,0,0) 
    //console.log(this.selectedMomentStart);
    this.activityLogsDataX.forEach(r => {
      if(r.date >= this.selectedMomentStart && r.date <= this.selectedMomentEnd){
        //console.log(r.date);
        if(this._date.toString() == r.date.toString() && this._userid == r.userid){
          var _count: number = this.activityLogsDataSource[this._idx -1].count;
          this.activityLogsDataSource[this._idx -1].count = _count+1;
        }
        else{
        this.activityLogsDataSource.push({year:r.year, month: r.month, day: r.day, date:r.date, userid: r.userid, username: r.username, count: 1})
        this._idx++;
        }
        this._date = new Date(r.date);
        this._userid = r.userid; 
      }
    })

    this.activityLogsDataSource.forEach(r => {
      this.db.object('users/'+r.userid).valueChanges().subscribe((res:any) => {
        var _dt = this.pipe.transform(r.date, 'MM/dd/yyyy');
        this.activityLogsDXSource.push({arg: _dt, val: r.count, name: res.n})
      })      
    })
    //console.log(this.activityLogsDXSource);
  }

  // BindChart(){
  // var chartOrders = document.getElementById('chart-orders');

  //   parseOptions(Chart, chartOptions());


  //   var ordersChart = new Chart(chartOrders, {
  //     type: 'bar',
  //     options: chartExample2.options,
  //     data: {
  //       labels: this.arrLabel,
  //       datasets: [
  //         {
  //           label: "Traffic",
  //           data: this.arrData,
  //           xAxisID: 'x-axis-2'
  //         },
  //         {
  //           label: "Last Week",
  //           data: this.arrLstWeekData,
  //           backgroundColor: 'rgba(211,211,211,0.45)'
  //         }
  //       ]
  //     }
  //   });
  // }

  customizeTooltip(arg: any) {
    //console.log(arg);
    const items = arg.valueText.split('\n');
    const color = arg.point.getColor();
    items.forEach((item, index) => {
      if (item.indexOf(arg.seriesName) === 0) {
        const element = document.createElement('span');
        var name = arg.points[index].point.data.name;
        element.textContent = item+ (name != ""?' ('+name+')':"");
        element.style.color = color;
        element.className = 'active';

        items[index] = element.outerHTML;
      }
    });
    return { text: items.join('\n') };
  }

  
  back() {
    this.router.navigate(["/home"]);
  }
  dataValidation() {
    return true;
    
  }
}


// export class Users{
//   EmployeeId: number;
//     EmployeeName: string;
//     UserName: string;
//     Password: string;
//     ConfirmPassword:string;
//     OldPassword:string;
//     IsActive: boolean;
//   }
// export class SiteConfig{
//   showLanguageOptions: boolean;
//   showBrochures: boolean;
//   showVideos: boolean;
//   backgroundColor: string;
//   bannerColor: string;
//   footerColor: string;
//   titleColor: string;
// }