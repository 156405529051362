
<div class="top-menu">
    <dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
    </dx-menu>
    </div>
    
    
    <div class="content-block dx-card responsive-paddings">
    <dx-data-grid #grid [dataSource]="dataSource" keyExpr="languageId" [focusedRowEnabled]="true" [selectedRowKeys]="[]"
    [showRowLines]="true" [hoverStateEnabled]="true" >
      
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager 
        
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 20]"
        [showInfo]="true">
    </dxo-pager>
      <dxo-selection selectAllMode="allPages" mode="single"></dxo-selection>
      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="GridLanguagelist"></dxo-state-storing>
    
    
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
      <dxo-load-panel [enabled]="false">
      </dxo-load-panel>
    
        
       
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    
        <dxi-column dataField="languageId" [width]="170"></dxi-column>
        <dxi-column dataField="languageName"></dxi-column>
        
    
    </dx-data-grid>
    </div>
    
    
