
 <ngx-loading-bar [color]="'#03a9f4'" [height]="'3px'" ></ngx-loading-bar>

  
   <ng-container *ngIf="isAuthenticated(); else unauthenticated">
      <app-side-nav-inner-toolbar title="Public Service Center - {{userName}}" >
      <router-outlet></router-outlet>
    </app-side-nav-inner-toolbar>
  </ng-container>
  
  <ng-template #unauthenticated>
    <app-unauthenticated-content></app-unauthenticated-content>
  </ng-template>
  