import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMain } from 'src/app/app.main';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private http: HttpClient,
    private appMain: AppMain
  ) { }

  public getStore() {
    return this.http.get(this.appMain.apiURL +"Store").toPromise();
  }
  public getStoreByStoreId(storeId:number) {
    return this.http.get(this.appMain.apiURL + "Store/"+ storeId).toPromise();
  }
  public saveStore(formData: any) {
    return this.http.post(this.appMain.apiURL + "Store", formData).toPromise();
  }
  public updateStore(storeId:number,formData: any) {
    return this.http.put(this.appMain.apiURL + "Store/"+ storeId, formData).toPromise();
  }
  public deleteStore(storeId: number){
    return this.http.delete(this.appMain.apiURL + "Store/" + storeId).toPromise();
  }
  public getCboStore() {
    return this.http.get(this.appMain.apiURL +"CboStore").toPromise();
  }
  public getBrouchureByStore(storeId: number){
    return this.http.get(this.appMain.apiURL + "BrouchureByStore/"+ storeId).toPromise();

  }
  public getVideoByStore(storeId: number){
    return this.http.get(this.appMain.apiURL + "VideoByStore/"+ storeId).toPromise();

  }
}
