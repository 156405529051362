<div class="top-menu">
  <dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
  </dx-menu>
</div>


<div class="content-block dx-card responsive-paddings">
  <dx-data-grid #grid [dataSource]="dataSource" keyExpr="storeId" [focusedRowEnabled]="true" [selectedRowKeys]="[]"
    [showRowLines]="true" [hoverStateEnabled]="true" (onRowDblClick)="Rowdblclick();"
    (onContentReady)="onContentReady($event)" [showBorders]="true" [selectedRowKeys]="[]" (onRowClick)="Rowclick();">

    <dxo-paging [pageSize]="5"></dxo-paging>
    <dxo-pager  [allowedPageSizes]="[5,10,15]" [showInfo]="true">
    </dxo-pager>
    <dxo-selection selectAllMode="allPages" mode="single"></dxo-selection>



    <!-- <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-group-panel [visible]="true"></dxo-group-panel> -->
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-load-panel [enabled]="false">
    </dxo-load-panel>



    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

    <dxi-column dataField="storeId" [width]="170"></dxi-column>
    <dxi-column dataField="storeName"></dxi-column>
    <dxi-column dataField="location"></dxi-column>
    <dxi-column dataField="contactPerson"></dxi-column>
    <dxi-column dataField="contactNumber"></dxi-column>
    <dxi-column dataField="note"></dxi-column>
    <!-- <dxi-column dataField="isActive" dataType="boolean"></dxi-column> -->



  </dx-data-grid>
</div>


<div class="content-block dx-card responsive-paddings" style="margin-top: 30px;">
  <div class="title">
    <h4><b>Store Details</b></h4>
  </div>
  <dx-form [colCount]="2">
    <dxi-item itemType="group" *ngIf="rowSelected" [colSpan]=3 [colCount]="2" caption="Brochure" >
     
      <dx-data-grid [dataSource]="brouchurelist" showBorders="true" [columnAutoWidth]="true">

        <dxi-column dataField="brouchureDocument" caption="BrochureDocument" ></dxi-column>
        <dxi-column dataField="languageName"  ></dxi-column>
        <dxi-column dataField="brouchureTitle" caption="BrochureTitle" ></dxi-column>
      </dx-data-grid>

    </dxi-item>

    <dxi-item itemType="group" *ngIf="rowSelected" [colSpan]=3 [colCount]="2" caption="Video" >
      
      <dx-data-grid [dataSource]="videolist" showBorders="true" [columnAutoWidth]="true">

        <dxi-column dataField="videoDocument"></dxi-column>
        <dxi-column dataField="languageName"></dxi-column>
        <dxi-column dataField="videoTitle"></dxi-column>
      </dx-data-grid>

    </dxi-item>

  </dx-form>

</div>