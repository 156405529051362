import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";

// import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/do'; 
import { of } from "rxjs";
import { AuthService } from "../shared/services";




@Injectable() 
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router,private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
         if (req.headers.get('No-Auth') == "True"){
            req.headers.delete('No-Auth');
            return next.handle(req.clone());
        }

        if (localStorage.getItem('boUserToken') != null) {
            const clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('boUserToken'))
            });
            return next.handle(clonedreq)
                .do(
                    succ => { },
                    err => {
                        if (err.status === 401)
                            //this.router.navigateByUrl('/login');
                            this.authService.logOut();
                            //this.router.navigate(["login"]);
                    }
                );
        }
        else {
            this.authService.logOut();
            //this.router.navigateByUrl('/login');
            //  this.router.navigate(["/login"]);
            //  return of({}) as Observable<HttpEvent<any>>;
          
        }
    }
}