import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { AppMain } from 'src/app/app.main';
import { AgenciesService } from '../../Agencies/agencies.service';
import { LanguageService } from '../../language/language.service';
import { StoreService } from '../../store/store.service';
import { BrouchureService } from '../brouchure.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-brouchure',
  templateUrl: './brouchure.component.html',
  styleUrls: ['./brouchure.component.scss']
})
export class BrouchureComponent implements OnInit {
  value: any[] = [];
  menus: any[];
  fileToUpload: File = null;
  fileToDocument: File = null;
  imgURL: string = '';// this.NullImage;
  documentImgURL: string = '';// this.NullImage;
  thumbBase64: string = '';
  documentBase64: string = '';
  hasImageChanged: boolean = false;
  useButtons: string;
  formData: brouchure;
  brouchureId: number = 0;
  iconvisible: boolean = false;
  Languages = [];
  Agencies = [];
  Stores = [];
  isMultiline: boolean = true;

  uploadAPI: string = "";
  pdfURL: string = "";
  brouchureAgencies: BrouchureAgencies[] = [];
  brouchureStores: BrouchureStore[] = [];
  records = []; recordId = ''; recEditId = ''; recEdit:any = {};
  docFilePath = ''; docFileName = '';
  imgFileName = '';
  @ViewChild("form", { static: false }) form: DxFormComponent

  // GRNDetails: GRNDetails[];
  constructor(
    private router: Router,
    private appMain: AppMain,
    private route: ActivatedRoute,
    private service: BrouchureService,
    private serviceLanguage: LanguageService,
    private serviceAgencies: AgenciesService,
    private serviceStore: StoreService, public db: AngularFireDatabase, public afStore: AngularFireStorage, private svcAku: SvcAku,
  ) {
      this.menus = [
        { brouchureId: "save", name: "Save", icon: "save", location: "after" },
        { brouchureId: "back", name: "Back", icon: "back", location: "after" }
      ];
    }

  ngOnInit(): void {
    this.uploadAPI = this.appMain.apiURL + "FileUpload";
    this.pdfURL = this.appMain.fileURL +"Images/Brouchure/";

    this.formData = {
      brouchureId: 0, brouchureTitle: "", brouchureThumbnail: "", brouchureDocument: "",
      languageId: 0, agenciesId:0, thumbBase64: "", documentBase64: "",brouchureDescription:"", isActive:true,
      brouchureStore: [],brouchureAgencies:[]
    }
    // this.serviceLanguage.getCboLanguage().then((res: any) => {
    //   this.Languages = res.language;
    // });
    // this.serviceAgencies.getCboAgencies().then((res: any) => {
    //   this.Agencies = res.agencies;
    // });
    // this.serviceStore.getCboStore().then((res: any) => {
    //   this.Stores = res.store;
    //   console.log(this.Stores);
    // });

    this.brouchureId = Number(this.route.snapshot.paramMap.get('brouchureId'));
    this.db.list('lang').snapshotChanges().subscribe(resLang=>{
      this.dataBindLanguages(resLang);
      this.db.list('agency').snapshotChanges().subscribe(resAgency=>{
        this.dataBindAgencies(resAgency);
        this.db.list('store').snapshotChanges().subscribe(resStore=>{
          this.dataBindStores(resStore);

          var _subBrochure = this.db.list('brochure').snapshotChanges().subscribe(res=>{
            _subBrochure.unsubscribe();
            var _resList = this.svcAku.fireRecords(res);
            this.records = [];
            _resList.forEach(r=>{
              this.records.push({ 
                brouchureId: r.id, brouchureTitle: r.n, brouchureDescription: r.d, brouchureDocument: r.doc, brouchureThumbnail: r.tn, languageId: r.langId//, languageName: _langName, appId: r.appId
              });
            });
            if (this.brouchureId == 0) // New mode
            { }
            else {  // Update mode
              var _arr = _resList.filter(x=>x.id == this.brouchureId);
              if(_arr.length<1){
                alert('Record not found! ' + this.brouchureId);
                return;
              }
              console.log('edit', _arr[0]);
              this.recEdit = _arr[0];
              this.editImageLoad(this.recEdit);
              this.recEditId = this.recEdit.appId;
              this.formData.brouchureTitle = this.recEdit.n;
              this.formData.brouchureDescription = this.recEdit.d;
              this.formData.languageId = this.recEdit.langId;
              this.formData.isActive = this.recEdit.s == 1;
              this.docFilePath = this.recEdit.docUrl;
              this.docFileName = this.recEdit.doc;
              var _storeList = [];
              if(this.recEdit.stores && this.recEdit.stores.length>0){
                this.recEdit.stores.forEach(s => {
                  _storeList.push({storeId: s.id, brouchureId: 0});
                });
              }
              this.formData.brouchureStore = _storeList;
              var _agencyList = [];
              if(this.recEdit.agencies && this.recEdit.agencies.length>0){
                this.recEdit.agencies.forEach(s => {
                  _agencyList.push({agenciesId: s.id, brouchureId: 0});
                });
              }
              this.formData.brouchureAgencies = _agencyList;
              // var _arr = this.records.filter(x=>x.agenciesId == this.brouchureId);
              // if(_arr.length>0){
              //   this.formData = _arr[0];
              //   this.recordId = _arr[0].appId;
              // }
              // this.service.getBrouchureByBrouchureId(this.brouchureId).then((res: any) => {
              //   console.log('b detail', res);
              //   this.formData = res.brouchure;
              //   this.formData.brouchureStore = res.store;
              //   this.formData.brouchureAgencies = res.agencies;
              //   this.imgURL = this.appMain.imageURL + this.formData.brouchureThumbnail;
              //   this.documentImgURL = this.appMain.imageURL + this.formData.brouchureDocument;
              // })
            }
          });
          // this.service.getBrouchureQRByBrouchureId(this.brouchureId).then((res: any) => {
          //   this.formData = res.brouchure;})

        });
      });
    });
  }
  dataBindLanguages(resLang){
    var _langList = this.svcAku.fireRecords(resLang);
    this.Languages = [];
    _langList.forEach(r=>{
      if(r.s == 2) return;
      this.Languages.push({
        languageId: r.id, languageName: r.n
      })
    });
  }
  dataBindAgencies(resAgency){
    var _agencyList = this.svcAku.fireRecords(resAgency);
    this.Agencies = [];
    _agencyList.forEach(r=>{
      if(r.s == 2) return;
      this.Agencies.push({
        agenciesId: r.id, agenciesName: r.n
      })
    });
  }
  dataBindStores(resStore){
    var _storeList = this.svcAku.fireRecords(resStore);
    this.Stores = [];
    _storeList.forEach(r=>{
      if(r.s == 2) return;
      this.Stores.push({
        storeId: r.id, storeName: r.n
      })
    });
  }
  editImageLoad(argEdit){
    this.db.list(`brochure-img/${argEdit.appId}`).snapshotChanges().subscribe(resImg=>{
      var _imgList = this.svcAku.fireRecords(resImg);
      if(_imgList.length>0){
        argEdit.imgB64 = _imgList[0].b64;
        this.imgURL = _imgList[0].b64;
      }
    });
  }
  clearImage() {
    this.imgURL = "";
    this.iconvisible = false;
  }
  loadImage(e) {
    if (e.value.length) {
      this.fileToUpload = e.value[0];
      this.imgFileName = this.fileToUpload.name;
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.thumbBase64 = event.target.result;
        this.hasImageChanged = true;
        this.iconvisible = false;
        this.imgURL = event.target.result;
      }
      reader.readAsDataURL(this.fileToUpload);
    }
    else
      this.imgURL = "";
    this.hasImageChanged = true;
  }
  loadDocumentImage(e) {
    if (e.value.length) {
      this.fileToDocument = e.value[0];
      this.docFileName = this.fileToDocument.name;
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.documentBase64 = event.target.result;
        // this.hasImageChanged = true;
        // this.iconvisible = false;
        this.documentImgURL= event.target.result;
      }
      reader.readAsDataURL(this.fileToDocument);
    }
    // else
    //   this.imgURL = "";
    // this.hasImageChanged = true;
  }
  onUploaded(e) {
    console.log(e);
  }
  //string to int
  dataValidation(): boolean {
    for (var i = 0; i <= this.formData.brouchureStore.length - 1; i++)
      this.formData.brouchureStore[i].brouchureId = i;

      for (var i = 0; i <= this.formData.brouchureAgencies.length - 1; i++)
      this.formData.brouchureAgencies[i].brouchureId = i;
    return true;
  }
  save() {
    // validation check here
    this.dataValidation();
    this.formData.thumbBase64 = this.thumbBase64;
    if (this.fileToDocument != null)
      this.formData.brouchureDocument = this.fileToDocument.name;

    var result = this.form.instance.validate();
    if (!result.isValid) {
      return;
    }

    if(this.formData.isActive == null) this.formData.isActive=false;

    var _agencies = [];
    var _stores = [];
    this.formData.brouchureAgencies.forEach(a=>{
      _agencies.push({id: a.agenciesId, s: 1});
    });
    this.formData.brouchureStore.forEach(a=>{
      _stores.push({id: a.storeId, s: 1});
    });

    if (this.brouchureId == 0) { // add new
      //console.log(this.formData);
      var _newId = 1;
      var _recMax = this.records.reduce((max, r)=> (r.brouchureId > max ? r.brouchureId:max), this.records[0].brouchureId);
      if(_recMax) _newId = _recMax+1;
      var _rec:any = {
        id: _newId,
        n: this.formData.brouchureTitle,
        d: this.formData.brouchureDescription,
        langId: this.formData.languageId,
        s: this.formData.isActive ? 1:0,
        tn: this.imgFileName,
        stores: _stores,
        agencies: _agencies
      };

      var _recId = this.svcAku.stringAppId('B');
      // console.log('new:', _rec);
      // console.log(this.formData.brouchureDocument);
      //if(1==1) return;
      //console.log('img:', this.imgURL);
      //console.log('doc:', this.documentImgURL);
      var _imgB64 = this.imgURL;//.split(',')[1];
      var _recImgId = this.svcAku.stringAppId('BI');
      this.db.object(`brochure-img/${_recId}/${_recImgId}`).set({b64: _imgB64}).then(resImg=>{
        //var _recDocId = this.svcAku.stringAppId('BD');
        //this.db.object(`brochure-doc/${_recId}/${_recDocId}`).set({b64: this.documentImgURL}).then(resImg=>{
        const refStore = this.afStore.ref(`/brochures/${_recId}/${this.formData.brouchureDocument}`);
        var _docB64 = this.documentImgURL.split(',')[1];
        refStore.putString(_docB64, 'base64', {contentType: 'application/pdf'}).then((resUpload:any)=>{
          console.log(`uploaded:`, resUpload);
        }).then(()=>{
          refStore.getDownloadURL().subscribe(resUrl=>{
            _rec.docUrl = resUrl;
            _rec.doc = this.formData.brouchureDocument;
            this.db.object(`brochure/${_recId}`).set(_rec).then(r=>{
              this.back();
            }).catch(err=>{
              alert('Error: ' + err);
            });
          });
          // this.service.saveBrouchure(this.formData).then((res: any) => {
          //   alert(res.message);
          //   if (res.status == true)
          //     this.back();
          // })
        }).catch(errDoc=>{
          alert('Pdf upload failed. ' + errDoc);
          return;
        });
      }).catch(errImg=>{
        console.log(errImg);
        alert('Image upload failed. ' + errImg);
        return;
      });
    } else { // udpate
      this.saveUpdate(_stores, _agencies);
    }
  }
  saveUpdate(_stores, _agencies){
    var _recEdit = {
      n: this.formData.brouchureTitle,
      d: this.formData.brouchureDescription,
      langId: this.formData.languageId,
      s: this.formData.isActive ? 1:0,
      stores: _stores,
      agencies: _agencies,
    };
    this.db.object(`brochure/${this.recEditId}`).update(_recEdit).then(()=>{
      //console.log(this.hasImageChanged, this.recEdit);
      if(this.hasImageChanged){
        var _imgB64 = this.imgURL;//.split(',')[1];
        var _recImgId = this.svcAku.stringAppId('BI');
        this.db.object(`brochure-img/${this.recEdit.appId}`).remove().then(()=>{
          this.db.object(`brochure-img/${this.recEdit.appId}/${_recImgId}`).set({b64: _imgB64}).then(resImg=>{
            //console.log('img updated');
            this.saveUpdateDoc();
          });
        }).catch(errImgRemove=>{
          alert('Failed to replace existing image! ' + errImgRemove);
        })
      }
      else this.saveUpdateDoc();
    });
    // this.service.updateBrouchure(this.brouchureId, this.formData).then((res: any) => {
    //   alert(res.message);
    //   if (res.status == true)
    //     this.back();
    // })
  }
  saveUpdateDoc(){
    if(this.documentImgURL && this.documentImgURL.length>0){
      const refStore = this.afStore.ref(`/brochures/${this.recEdit.appId}/${this.formData.brouchureDocument}`);
      var _docB64 = this.documentImgURL.split(',')[1];
      refStore.putString(_docB64, 'base64', {contentType: 'application/pdf'}).then((resUpload:any)=>{
        //console.log(`uploaded:`, resUpload);
      }).then(()=>{
        refStore.getDownloadURL().subscribe(resUrl=>{
          this.db.object(`brochure/${this.recEditId}`).update({
            docUrl: resUrl,
            doc: this.formData.brouchureDocument
          }).then(resDoc=>{
            //console.log('img updated');
            this.back();
          }).catch(errDoc=>{
            alert('Document upload error! ' + errDoc);
            return;
          });
        });
      });
    }
    else{
      this.back();
    }
  }
  menuClick(e) {
    if (e.itemData.brouchureId == "save") {
      this.save();
    }
    else if (e.itemData.brouchureId == "back") {
      this.back();
    }
  }
  back() {
    this.router.navigate(["/Brouchurelist"]);
  }
}
export interface BrouchureStore {
  brouchureId: number;
  storeId: number;

}
export interface BrouchureAgencies {
  brouchureId: number;
  agenciesId: number;

}
export interface brouchure {
  brouchureId: number;
  brouchureTitle: string;
  brouchureThumbnail: string;
  brouchureDocument: string;
  languageId: number;
  agenciesId: number;
  thumbBase64: string;
  documentBase64: string;
  brouchureDescription:string;
  isActive: boolean;

  brouchureStore: BrouchureStore[];
  brouchureAgencies: BrouchureAgencies[];
}

