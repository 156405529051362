<div class="top-menu">
  <dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
  </dx-menu>
  </div>
  
  
  <div class="content-block dx-card responsive-paddings">
  
    <dx-form #form brouchureId="form" [colCount]="3" [(formData)]="formData">
      <dxi-item itemType="group" [colSpan]=3 [colCount]="1" caption="Brochure">
        <dxi-item dataField="brouchureId" [label]="{text: ('BrochureId')}" [visible]=false></dxi-item>
        <dxi-item dataField="brouchureTitle" [label]="{text: ('BrochureTitle')}" [editorOptions]="{ maxLength: '50'}" >
          <dxi-validation-rule type="required" message="Brochure Title is required">
          </dxi-validation-rule>
          <dxi-validation-rule type="stringLength" [max]="50" message="Brochure Title length must be less than 50">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="languageId" [label]="{text: ('Language')}" editorType="dxSelectBox" [editorOptions]="{ items: Languages, searchEnabled: true, value: '', displayExpr:'languageName',
          valueExpr:'languageId' }">
          <dxi-validation-rule type="required" message="Language Id is required">
          </dxi-validation-rule>
        </dxi-item>
        <!-- <dxi-item dataField="agenciesId" [label]="{text: ('Agencies')}" editorType="dxSelectBox" [editorOptions]="{ items: Agencies, searchEnabled: true, value: '', displayExpr:'agenciesName',
        valueExpr:'agenciesId'}">
        <dxi-validation-rule type="required" message="Agencies Id is required">
        </dxi-validation-rule>
      </dxi-item> -->
      <dxi-item dataField="brouchureDescription" [label]="{text: ('Brochure Description')}" [editorOptions]="{ maxLength: '250'}">
        <dxi-validation-rule type="stringLength" [max]="250" message="Brochure Description length must be less than 250">
        </dxi-validation-rule>
      </dxi-item> 
      <dxi-item dataField="isActive" editorType="dxCheckBox"></dxi-item>
      </dxi-item>
      <dxi-item itemType="group" [colSpan]=3 [colCount]="2">
        <dxi-item itemType="tabbed" [tabPanelOptions]="{ deferRendering: false }">
          <dxi-tab title="brochure Thumbnail-Images">
            <dxi-validation-rule type="required" message="brochure Thumbnail-Images is required">
            </dxi-validation-rule>

            <div class="help">
              <span class="note">Image size : <span>Width : 399px * Height : 822px</span>.</span>
            </div>
            
            <div class="file-uploader-block">
              <dx-file-uploader #file selectButtonText=" Choose brouchure Thumbnail-Images" [uploadMode]="useButtons"
                accept='image/*' (onOptionChanged)="loadImage($event)">
              </dx-file-uploader>
              <dx-button icon="close" class="dx-fileuploader-button-container dx-fileuploader-button"
                (onClick)="clearImage()" *ngIf="iconvisible">
              </dx-button>
              <img [src]="imgURL" height="200" *ngIf="imgURL">
            </div>
            <!-- <div class="help">
                  <span class="note">Maximum file size: <span>4 MB</span>.</span>
                </div> -->
             
          </dxi-tab>
  
          <dxi-tab title="Brochure Document">
            <dxi-validation-rule type="required" message="Brochure Document is required">
            </dxi-validation-rule>
            <dx-file-uploader
              #fileUploader  name="files[]"
              [multiple]="false"
              accept=".pdf" 
              [(value)]="value"
              [uploadMode]="useButtons"
              (onOptionChanged)=" loadDocumentImage($event)"
              (onFileUploaded)="onUploaded($event)"></dx-file-uploader>
            <!-- <dx-file-uploader
              #fileUploader  name="files[]"
              [uploadUrl]="uploadAPI"
              [multiple]="false"
              accept=".pdf" 
              [(value)]="value"
              uploadMode="instantly"
              (onOptionChanged)=" loadDocumentImage($event)"
              (onFileUploaded)="onUploaded($event)"></dx-file-uploader> -->
        <!-- <div *ngIf="formData.brouchureDocument">
          <a href="{{pdfURL+formData.brouchureDocument}}" target="_blank"> Selected File Name : {{formData.brouchureDocument}}</a>
          <br>
        </div> -->
        <div *ngIf="docFilePath && docFilePath.length>0">
          <a href="{{docFilePath}}" target="_blank"> Selected File Name : {{docFileName}}</a>
          <br>
        </div>
  
            <!-- <div class="file-uploader-block">
                    <dx-file-uploader #file selectButtonText=" Choose brouchureDocument" [uploadMode]="useButtons" accept='image/*'
                      (onOptionChanged)="loadDocumentImage($event)">
                    </dx-file-uploader>
                   
                    <dx-button icon="close" class="dx-fileuploader-button-container dx-fileuploader-button"
                      (onClick)="clearImage()" *ngIf="iconvisible">
                    </dx-button>
                    <img [src]="documentImgURL" height="200" *ngIf="documentImgURL">
                  </div> 
            <div class="help">
              <span class="note">Maximum file size: <span>4 MB</span>.</span>
            </div>-->
            
          </dxi-tab>
        </dxi-item>
      </dxi-item>
   </dx-form>
  </div>
  <div class="content-block dx-card responsive-paddings" style="margin-top: 30px;">
    <div class="title"></div>
    <dx-form [colCount]="2">
  <dxi-item itemType="group" [colSpan]=3 [colCount]="1">
    <dxi-item itemType="tabbed" [tabPanelOptions]="{ deferRendering: false }">
  
      <dxi-tab title="Brochure Store">
  
        <dx-data-grid  [dataSource]="formData.brouchureStore" keyExpr="brouchureId" [focusedRowEnabled]="true"
          showBorders="true" [columnAutoWidth]="true" >
          <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
          </dxo-editing>
          <dxi-column dataField="brouchureId" [visible]=false></dxi-column>
          <dxi-column dataField="storeId" caption="Store Name"  >
            <dxo-lookup [dataSource]="Stores" valueExpr="storeId" displayExpr="storeName">
            </dxo-lookup>
            <dxi-validation-rule type="required" message="Store Name  is required">
            </dxi-validation-rule>
          </dxi-column>
        </dx-data-grid>
      </dxi-tab>
      </dxi-item>
  </dxi-item>
  
      <dxi-item itemType="group" [colSpan]=3 [colCount]="1">
        <dxi-item itemType="tabbed" [tabPanelOptions]="{ deferRendering: false }">
      <dxi-tab title="Brochure Agencies">
  
        <dx-data-grid  [dataSource]="formData.brouchureAgencies" keyExpr="brouchureId" [focusedRowEnabled]="true"
          showBorders="true" [columnAutoWidth]="true" >
          <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
          </dxo-editing>
          <dxi-column dataField="brouchureId" [visible]=false></dxi-column>
          <dxi-column dataField="agenciesId" caption="Agencies Name"  >
            <dxo-lookup [dataSource]="Agencies" valueExpr="agenciesId" displayExpr="agenciesName">
            </dxo-lookup>
            <dxi-validation-rule type="required" message="Agencies Name  is required">
            </dxi-validation-rule>
          </dxi-column>
        </dx-data-grid>
      </dxi-tab>
  
    </dxi-item>
  </dxi-item>
    </dx-form>
  </div>