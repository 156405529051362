<div class="top-menu">
<dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
</dx-menu>
</div>

<div class="content-block dx-card responsive-paddings">
<dx-data-grid #grid [dataSource]="dataSource" keyExpr="userName" [focusedRowEnabled]="true" [selectedRowKeys]="[]"
  [showRowLines]="true" [hoverStateEnabled]="true" (onRowDblClick)="Rowdblclick();"
  (onContentReady)="onContentReady($event)">

  <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager 
    [showPageSizeSelector]="true"
        [allowedPageSizes]="[5,10,15]"
        [showInfo]="true">
    </dxo-pager>
  <dxo-load-panel [enabled]="false">
  </dxo-load-panel>
  <dxo-selection selectAllMode="allPages" mode="single"></dxo-selection>
 <!-- <dxo-state-storing [enabled]="true" type="localStorage" storageKey="Griduserlist"></dxo-state-storing> -->


  <!-- <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-group-panel [visible]="true"></dxo-group-panel> -->
  <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
  <dxi-column dataField="employeeId" [width]="170"></dxi-column>
  <dxi-column dataField="userName"></dxi-column>
  <dxi-column dataField="employeeName"></dxi-column>
  <dxi-column dataField="storeName"></dxi-column>
  <!--   <dxi-column dataField="password"></dxi-column> -->
  <!-- <dxi-column dataField="oldPassword"></dxi-column><br> -->
  <!-- <dxi-column dataField="isActive" dataType="boolean"></dxi-column> -->
  <dxi-column dataField="isAdmin" dataType="boolean"></dxi-column>
</dx-data-grid>


<dx-popup [width]="450" [height]="350" [showTitle]="true" title="Reset-Password" [dragEnabled]="false"
  [closeOnOutsideClick]="true" [(visible)]="popupVisible">
  <dx-form #form userName="form" [colCount]="3" [(formData)]="formData">
    <dxi-item itemType="group" [colSpan]=3 [colCount]="1">
      <dxi-item dataField="UserName" [label]="{text:('UserName')}">
      </dxi-item>
      <dxi-item dataField="Password" [label]="{text:('New Password')}">
        <dxi-validation-rule type="required" message="New Password name is required">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="ConfirmPassword" [label]="{text:('Confirm Password')}">
        <dxi-validation-rule type="required" message="Confirm Password name is required">
        </dxi-validation-rule>
      </dxi-item>
    </dxi-item>
    <dxi-item [colSpan]="3" style="float: right">
      <dx-button stylingMode="contained" text="Reset" type="default" (click)="resetpassword()" [width]="120">
      </dx-button>
    </dxi-item>
  </dx-form>
</dx-popup>
</div>