
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMain } from 'src/app/app.main';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(
    private http: HttpClient,
    private appMain: AppMain
  ) { }
  public getVideo() {
    return this.http.get(this.appMain.apiURL + "Video").toPromise();
  }
  public getVideoByVideoId(videoId:number) {
    return this.http.get(this.appMain.apiURL + "Video/"+videoId).toPromise();
  }
  public saveVideo(formData: any) {
    return this.http.post(this.appMain.apiURL + "Video", formData).toPromise();
  }
  public updateVideo(videoId:number,formData: any) {
    return this.http.put(this.appMain.apiURL + "Video/"+videoId, formData).toPromise();
  }
  public deleteVideo(videoId: number){
    return this.http.delete(this.appMain.apiURL +"Video/" + videoId).toPromise();
  }

}
