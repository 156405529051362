import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { AngularFireDatabase } from '@angular/fire/database'
import { StoreService } from '../store.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  menus: any[]; storeList = [];
  formData: store;
  storeId: number = 0; recordId = '';
  @ViewChild("form", { static: false }) form: DxFormComponent;
  isSA = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private service: StoreService, private db: AngularFireDatabase, private svcAku: SvcAku
  ) {
    this.menus = [
      { storeId: "save", name: "Save", icon: "save", location: "after" }, 
      { storeId: "back", name: "Back", icon: "back", location: "after" }];
  }

  ngOnInit(): void {
    var _loginUserName = localStorage.getItem("boUserName").trim().toLowerCase();
    this.isSA = _loginUserName == 'dev@email.com' || _loginUserName == 'sa@email.com';
    if(!this.isSA || 1==1){
      this.menus = [];
    }
    this.formData = { storeId:0,storeName:"",location:"",contactPerson:"",contactNumber:0,note:"",isActive:true }
    this.storeId = Number(this.route.snapshot.paramMap.get('storeId'));
    this.db.list('store').snapshotChanges().subscribe(res=>{
      var _resList = this.svcAku.fireRecords(res);
      this.storeList = [];
      _resList.forEach(r=>{
        this.storeList.push({ storeId: r.id, storeName: r.n, location: r.l, contactPerson: r.cp, contactNumber: r.cn, note: r.r, isActive: true, appId: r.appId });
      });
      if (this.storeId == 0) // New mode
      { }
      else {  // Update mode
        var _arr = this.storeList.filter(x=>x.storeId == this.storeId);
        if(_arr.length>0){
          this.formData = _arr[0];
          this.recordId = _arr[0].appId;
        }
        // this.service.getStoreByStoreId(this.storeId).then((res: any) => {
        //   this.formData = res.store;
        // })
      }
    });
  }
  back() {
    this.router.navigate(["Storelist"]);
  }
  save() {
    // validation check here
    var result = this.form.instance.validate();
    if (result.isValid) {
      if(this.formData.isActive == null) this.formData.isActive=false;
      if (this.storeId == 0) { // add new
        var _newId = 1;
        //var _recMax = this.storeList.reduce((r1,r2)=>Math.max(r1,r2));
        var _recMax = this.storeList.reduce((max, r)=> (r.storeId > max ? r.storeId:max), this.storeList[0].storeId);
        if(_recMax) _newId = _recMax+1;
        var _recId = this.svcAku.stringAppId('S');
        this.db.object(`store/${_recId}`).set({
          id: _newId,
          n: this.formData.storeName,
          l: this.formData.location,
          cp: this.formData.contactPerson,
          cn: this.formData.contactNumber,
          s: 1,
          r: this.formData.note
        }).then(r=>{
          this.svcAku.log('A-STORE-ADD', _recId);
          this.back();
        }).catch(err=>{
          alert('Erro: ' + err);
        });
        // this.service.saveStore(this.formData).then((res: any) => {
        //   alert(res.message);
        //   if (res.status == true)
        //     this.back();
        // })
      } else { // Udpate
        this.db.object(`store/${this.recordId}`).set({
          id: this.formData.storeId,
          n: this.formData.storeName,
          l: this.formData.location,
          cp: this.formData.contactPerson,
          cn: this.formData.contactNumber,
          s: 1,
          r: this.formData.note
        }).then(r=>{
          this.svcAku.log('A-STORE-UPDATE', this.recordId);
          this.back();
        }).catch(err=>{
          alert('Erro: ' + err);
        });
        // this.service.updateStore(this.storeId, this.formData).then((res: any) => {
        //   alert(res.message);
        //   if (res.status == true)
        //     this.back();
        // })
      }
    }
  }
  menuClick(e) {
    if (e.itemData.storeId == "save") this.save();
    else if (e.itemData.storeId == "back") this.back();
  }
}
export class store{
  storeId:number;
  storeName:string;
  location:string;
  contactPerson:string;
  contactNumber:number;
  note:string;
  isActive: boolean;
}
