import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMain } from 'src/app/app.main';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private http: HttpClient,
    private appMain: AppMain
  ) { }

  public getLanguage() { 
    return this.http.get(this.appMain.apiURL +"Language").toPromise();
  }
  public getLanguageByLanguageId(languageId:number) {
    return this.http.get(this.appMain.apiURL + "Language/"+languageId).toPromise();
  }
  public saveLanguage(formData: any) {
    return this.http.post(this.appMain.apiURL + "Language", formData).toPromise();
  }
  public updateLanguage(languageId:number,formData: any) {
    return this.http.put(this.appMain.apiURL + "Language/"+languageId, formData).toPromise();
  }
  public deleteLanguage(languageId: number){
    return this.http.delete(this.appMain.apiURL + "Language/" +languageId).toPromise();
  }
  public getCboLanguage() {
    return this.http.get(this.appMain.apiURL +"CboLanguage").toPromise();
  }
}
