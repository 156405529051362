import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { StringLengthRule } from "devextreme/ui/validation_engine";
 
@Injectable()
export class AppMain {

  public imageURL: string;
  public apiURL: string;
  public serverip: string;
  public version: string;
  public fileURL:string;
  public isUserLoginIn:boolean=false;

  constructor(
    private http: HttpClient
  ) { }

  initializeApp(): Promise<any> {
    const httpOptions =  new HttpHeaders({
      'No-Auth': 'True'}) ;
    const promise = this.http.get('assets/Config.json', { headers: httpOptions })
      .toPromise()
      .then((res: any) => {
        this.imageURL = res.imageURL;
        this.apiURL = res.apiURL;
        this.serverip = res.serveripaddress;
        this.version = res.version;
        this.fileURL=res.fileURL;
        //this.versionCheck();
        return res;
      });
    return promise;
  }

  ngOnInit(): void {
  }

  public fillConfiguration() {

  }
  public versionCheck(){
    const httpOptions =  new HttpHeaders({ 'No-Auth': 'True' }) ;
    const promise = this.http.get('assets/Config.json?v=' + (new Date()).getSeconds(), { headers: httpOptions })
      .toPromise()
      .then((res: any) => {
        console.log('v', this.version, '-', res.version);
        if(this.version != res.version){
          this.version = res.version;
          console.log('reloading ************');
          //window.location.reload();
          return;
        }
        setTimeout(() => {
          this.versionCheck();
        }, 15*1000);
      });
  }
}
 