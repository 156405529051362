import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { BrouchureService } from '../brouchure.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';

@Component({
  selector: 'app-brouchurelist',
  templateUrl: './brouchurelist.component.html',
  styleUrls: ['./brouchurelist.component.scss']
})
export class BrouchurelistComponent implements OnInit {

  menus: any[]; records = []; langs = [];
  dataSource: any;
  @ViewChild("grid") grid: DxDataGridComponent;

  constructor(
    public service: BrouchureService, private svcAku: SvcAku, 
    public db: AngularFireDatabase, public afStore: AngularFireStorage,
    private http: HttpClient,
    private router: Router
  ) {
    this.menus = [
      { brouchureId: "addNew", name: "Add New", icon: "add", location: "after" },
      { brouchureId: "edit", name: "Edit", icon: "edit", location: "after" },
      // { brouchureId: "refresh", name: "Refresh", icon: "refresh", location: "after" },
      { brouchureId: "delete", name: "Delete", icon: "trash", location: "after" },
      // { brouchureId: "qrcode", name: "QR Code", icon: "edit", location: "after" }
    ];
  }

  ngOnInit(): void {
    this.loadGrid();
    this.svcAku.log('P-BROCHURE-LIST');
    //this.loadAndMigrate();
    //this.loadAndMigrateDetail();
  }
  loadGrid() {
    this.db.list('lang').snapshotChanges().subscribe(resLang=>{
      this.langs = this.svcAku.fireRecords(resLang);
      var _sub = this.db.list('brochure').snapshotChanges().subscribe(res=>{
        //_sub.unsubscribe();
        this.records = [];
        var _resList = this.svcAku.fireRecords(res);
        _resList.forEach((r, i)=>{
          if(r.s == 2) return;
          if(!r.docUrl){
            console.log('Not found', r.doc);
            //this.readFile(r);
            
          }
          //this.readFileUrl(r);
          // if(i==0)
          var _langName = '-';
          var _arrLang = this.langs.filter(x=>x.id == r.langId);
          if(_arrLang.length>0) _langName = _arrLang[0].n;
          this.records.push({
            brouchureId: r.id, brouchureTitle: r.n, isActive: r.s==1,
            brouchureDescription: r.d, brouchureDocument: r.doc, brouchureThumbnail: r.tn, languageId: r.langId, languageName: _langName, appId: r.appId
          });
        });
        this.dataSource = this.records;
      });
    });
    // this.service.getBrouchure().then((res: any) => {
    //   if (res.status == true) {
    //     this.dataSource = res.item;
    //     console.log(res.item);
    //   }
    // })
  }
  readFileUrl(arg){
    const refStore = this.afStore.ref(`/brochures/${arg.appId}/${arg.doc}`);
      refStore.getDownloadURL().subscribe(resUrl=>{
        console.log(resUrl);
        this.db.object(`brochure/${arg.appId}`).update({docUrl: resUrl});
      });
  }
  readFile(arg){
    // if(arg.appId != 'B-2021080116100196BK4L'){
    //   return;
    // }
    console.log('-- read file --');
    console.log(arg);
    if(arg.tn == undefined){
      console.log('>>>>> NO FILE');
      return;
    }
    var _url = 'http://localhost:52073/ping';
    //console.log(_url, arg.tn);
    //var _code = arg.tn+'xx';
    var _code = arg.doc;
    console.log('reading> ' + _code);
    this.http.post(_url, {code: _code}).subscribe((res:any)=>{
      if(res.Status != 'OK'){
        console.log('--- ERROR ---', res);
        return;
      }
      // var _recImgId = this.svcAku.stringAppId('BI');
      // this.db.object(`brochure-img/${arg.appId}/${_recImgId}`).set({b64: 'data:image/jpeg;base64,' + res.Data});
      console.log(res.Data.length);
      var _recDocId = this.svcAku.stringAppId('BD');
      //this.db.object(`brochure-doc/${arg.appId}/${_recDocId}`).set({b64: 'data:application/pdf;base64,' + res.Data});

      const refStore = this.afStore.ref(`/brochures/${arg.appId}/${arg.doc}`);
      refStore.putString(res.Data, 'base64', {contentType: 'application/pdf'}).then((resUpload:any)=>{
        console.log(`uploaded ${arg.doc} :`, resUpload);

        this.db.object(`brochure/${arg.appId}`).update({docUrl: resUpload.task.uploadUrl_});
      });
    });
  }
  fetchAsBlob = url => fetch(url, { mode: 'no-cors' }).then(response => response.blob());
  convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  loadAndMigrate(){
    this.service.getBrouchure().then((res: any) => {
      if (res.status == true) {
        console.log('migrate list:', res.item);
        res.item.forEach(r => {
          var _recId = this.svcAku.stringAppId('B');
          this.service.getBrouchureByBrouchureId(r.brouchureId).then((resItem:any)=>{
            //console.log(r.brouchureId, resItem);
            if(!resItem.status)
            {
              alert('Error');
              return;
            }
            var _rec = {
              id: r.brouchureId,
              n: r.brouchureTitle,
              d: r.brouchureDescription,
              doc: r.brouchureDocument,
              tn: r.brouchureThumbnail,
              langId: r.languageId,
              stores: [],
              agencies: [],
              s: 1
            };
            if(resItem.agencies){
              resItem.agencies.forEach(a => {
                _rec.agencies.push({id: a.agenciesId, s: 1});
              });
            }
            if(resItem.store){
              resItem.store.forEach(s => {
                _rec.stores.push({id: s.storeId, s: 1});
              });
            }
            //console.log(_rec);
            this.db.object(`brochure/${_recId}`).set(_rec).then(r=>{
              console.log('imported: ' + _rec.id);
            }).catch(err=>{
              alert('Erro: ' + err);
            });
          });
        });
      }
    })
  }
  loadAndMigrateDetail(){
    this.db.list('brochure').snapshotChanges().subscribe(res=>{
      var _resList = this.svcAku.fireRecords(res);
      _resList.forEach(r=>{
        this.service.getBrouchureByBrouchureId(r.id).then((res: any) => {
          console.log(res);
          // this.formData = res.brouchure;
          // this.formData.brouchureStore = res.store;
          // this.formData.brouchureAgencies = res.agencies;
          // this.imgURL = this.appMain.imageURL + this.formData.brouchureThumbnail;
          // this.documentImgURL = this.appMain.imageURL + this.formData.brouchureDocument;
        })
      });
      console.log(_resList);
    });
  }
  addNew() {
    this.router.navigate(["Brouchure"]);
  }
  edit(brouchureId: number) {
    this.router.navigate(['Brouchure/' + brouchureId]);
  }
  delete(brouchureId: number) {
    var _arr = this.records.filter(x=>x.brouchureId == brouchureId);
    if(_arr.length<1){
      alert(`No record found for ID: ${brouchureId}`);
      return;
    }
    if (!confirm(`Are you sure to delete "${_arr[0].brouchureTitle}" ?`)) {
      return;
    }
    this.db.object(`brochure/${_arr[0].appId}`).update({s:2}).then(r=>{

    }).catch(err=>{
      alert('Error: ' + err);
    });

    this.db.object(`brochure/${_arr[0].appId}`).update({s: 2});
    // for (let i = 0; i < this.dataSource.length; ++i) {
    //   if (this.dataSource[i].brouchureId=== brouchureId) {
    //     this.service.deleteBrouchure(brouchureId).then((res: any) => {
    //       this.dataSource.splice(i, 1);
    //     })
    //   }
    // }
  }
  menuClick(e) {
    if (e.itemData.brouchureId == "addNew") {
      this.addNew();
    }
    else if (e.itemData.brouchureId == "refresh") {
      this.loadGrid();
    }
    else if (e.itemData.brouchureId == "edit") {
      //console.log(this.grid.instance.getSelectedRowsData()[0]);
      var param = this.grid.instance.getSelectedRowsData()[0].brouchureId;
      this.edit(param);
    }
    else if (e.itemData.brouchureId == "delete") {
      var a = this.grid.instance.getSelectedRowsData()[0].brouchureId;
      this.delete(a);
    }
  }
  Rowdblclick() {
    var param = this.grid.instance.getSelectedRowsData()[0].brouchureId;
    this.edit(param);
  }
  onContentReady(e) {    
    this.grid.instance.selectRowsByIndexes([0]);
  }
}
