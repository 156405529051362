import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { UserService } from '../../user.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { SvcAuth } from 'src/app/_svcs/auth.svc';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  @ViewChild("form", { static: false }) form: DxFormComponent
  menus: any[];
  formData: Users;
  ConfirmPassword: string;
  isLoading: boolean = false;

  constructor(
    public service: UserService, private svcAuth: SvcAuth, private svcAku:SvcAku, private db:AngularFireDatabase,
    private router: Router,
  ) {
    this.menus = [
      { userName: "save", name: "Save", icon: "save", location: "after" },];
  }

  ngOnInit(): void {
    this.formData = {
      EmployeeId: 0,
      EmployeeName: 'Arun',
      UserName: 'Arun',
      OldPassword: '',
      ConfirmPassword:'',
      Password: '',
      IsActive: true,
    }
  }

  change() {
    //if (!this.dataValidation()) return;
    if(!this.form.instance.validate().isValid) return;
    if(this.formData.Password != this.formData.ConfirmPassword){
      alert('Password and Confirm Password should not be different!');
      return;
    }
    //console.log(localStorage.getItem("boUserName"), this.formData.ConfirmPassword, this.formData.OldPassword);
    var _login = localStorage.getItem("boUserName");
    this.svcAuth.loginValidate(_login, this.formData.OldPassword).then(res=>{
      //console.log(res);
      this.db.list('users').snapshotChanges().subscribe(res=>{
        var _records = this.svcAku.fireRecords(res).filter(r=>r.s!=2);
        var _arr = _records.filter(x=>x.un == _login);
        if(_arr.length<1){
          alert('User not able to find from list!');
          return;
        }
        var _rec = _arr[0]; 
        this.svcAuth.updateUser(_rec.appId, _rec.n, _rec.un, this.formData.ConfirmPassword).subscribe(res=>{
          //console.log(res);
          if(res.status != 'OK'){
            alert('Password failed!');
            return;
          }
          alert('Password updated!');
          this.router.navigate(["/login-form"]);
        });
      });
    }).catch(err=>{
      alert('Invalid Current Password!');
    });
    // var result = this.form.instance.validate();
    // {  
    //   this.service.ChangePassword(this.formData,localStorage.getItem("boUserName")).then((res: any) => {
    //     alert(res.message);
    //     this.isLoading = false;
    //     if(res.status == true)
    //       this.back();
    //   })
  
    // }
  }
  back() {
    this.router.navigate(["/home"]);
  }
  dataValidation() {
    if (this.formData.Password == this.formData.ConfirmPassword) {
      return true;
    }
    else{
      alert("Password and Confirm password not match");
    }
  }
  menuClick(e) {
    if (e.itemData.userName == "save") {
      console.log(this.formData);
      this.change();
    }
    else if (e.itemData.userName == "back") {
      this.back();
    }
  }
}


export class Users{
  EmployeeId: number;
    EmployeeName: string;
    UserName: string;
    Password: string;
    ConfirmPassword:string;
    OldPassword:string;
    IsActive: boolean;
  }
