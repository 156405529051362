import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { StoreService } from '../../store/store.service';
import { UserService } from '../user.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { SvcAuth } from 'src/app/_svcs/auth.svc';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  menus: any[];
  formData: User;
  userName: string;
  title: string;
  Stores = []; records = []; editId = '';
  viewMode = 'NEW';
  visible: boolean = false;

  // showPassword:boolean = true;
  // employeeId:number= 0;
  @ViewChild("form", { static: false }) form: DxFormComponent
  constructor(private router: Router,
    private route: ActivatedRoute,
    private serviceStore: StoreService,
    private service: UserService, private svcAku:SvcAku, private svcAuth:SvcAuth, private db:AngularFireDatabase
  ) {
    this.menus = [
      { userName: "save", name: "Save", icon: "save", location: "after" }, 
      { userName: "back", name: "Back", icon: "back", location: "after" }
    ];
  }
  ngOnInit(): void {
    this.formData = {employeeId:0,employeeName:"",isAdmin:false,
    userName:"",storeId:0,password:"",
    oldPassword:"1234567",isActive:true}

    this.userName = this.route.snapshot.paramMap.get('userName');
    // this.serviceStore.getCboStore().then((res: any) => {
    //   this.Stores = res.store;
    // });
    this.db.list('store').snapshotChanges().subscribe(resStore=>{
      var _storeList = this.svcAku.fireRecords(resStore);
      this.Stores = [];
      _storeList.forEach(r=>{
        if(r.s != 1) return;
        this.Stores.push({ storeId: r.id, storeName: r.n });
      });
      this.db.list('users').snapshotChanges().subscribe(res=>{
        this.records = this.svcAku.fireRecords(res).filter(r=>r.s!=2);
        
        if (this.userName==null) // add new mode
        {
          this.visible = true;
        }
        else { // update mode
          this.viewMode = 'EDIT';
          this.title = "Edit";
          this.visible = false;
          var _arr = this.records.filter(x=>x.n == this.userName);
          if(_arr.length<1){
            alert('User not found!');
            console.log(this.records);
            //this.back();
            return;
          }
          this.editId = _arr[0].appId;
          this.formData.userName = _arr[0].n;
          this.formData.isActive = _arr[0].s == 1;
          this.formData.isAdmin = _arr[0].a == 1;
          this.formData.employeeName = _arr[0].en;
          this.formData.storeId = _arr[0].storeId;
          // this.showPassword=false;
          // this.service.getGetUserDetailsByLoginName(this.userName).then((res: any) => {
          //   this.formData = res.user[0];
          // })
        }
      });
    });
  }
  back() {
    this.router.navigate(["UserList"]);
  }
  save() {
    // validation check here
    var result = this.form.instance.validate();

    if (result.isValid) {

      if(this.formData.isActive == null) this.formData.isActive=false;
      if(this.formData.isAdmin==null)this.formData.isAdmin=false;

      var _user:any = {
        n: this.formData.userName,
        en: this.formData.employeeName,
        un: this.formData.userName + '@ebrochure.com',
        a: this.formData.isActive ? 1 : 0,
        storeId: this.formData.storeId,
        s: 1
      };
      if (this.userName== null) { // add new
        var arr = this.records.filter(r=>r.n.toLowerCase() == this.formData.userName.toLowerCase());
        if(arr.length>0){
          alert("Duplicate User Name exists!");
          return;
        }
        this.svcAuth.register(_user.n, _user.un, this.formData.password).subscribe(res=>{
          //console.log(res);
          if(res.status != 'OK'){
            alert('User creation failed!');
            return;
          }
          var _newId = 1;
          var _recMax = this.records.reduce((max, r)=> (r.id > max ? r.id:max), this.records[0].id);
          if(_recMax) _newId = _recMax+1;
          var _newUserId = res.data;
          _user.id = _newId;
          this.db.object(`users/${_newUserId}`).set(_user).then(()=>{
            this.back();
          }).catch(err=>{
            alert('User info update failed!');
          });
        });
        // this.service.saveconfig_user(this.formData).then((res: any) => {
        //   alert(res.message);
        //   if (res.status == true)
        //     this.back();
        // })
      } else { // udpate
        this.db.object(`users/${this.editId}`).update({
          a: this.formData.isAdmin?1:0,
          en: this.formData.employeeName,
          storeId: this.formData.storeId
        }).then(()=>{
          this.svcAuth.updateUser(this.editId, _user.n, _user.un, this.formData.password).subscribe(res=>{
            //console.log(res);
            if(res.status != 'OK'){
              alert('Password failed!');
              return;
            }
            this.back();
          });
        }).catch(err=>{
          //console.log(err);
          alert('Update failed!');
        });
        // this.service.updateconfig_user(this.userName, this.formData).then((res: any) => {
        //   alert(res.message);
        //   if (res.status == true)
        //     this.back();
        // })
      }
    }
  }
  menuClick(e) {
    if (e.itemData.userName == "save") {
      console.log(this.formData);
      this.save();
    }
    else if (e.itemData.userName== "back") {
      this.back();
    }
  }
}


export class User{
    employeeId: number;
    employeeName: string;
    userName: string;
    storeId:number;
    password: string;
    oldPassword:string;
    isActive: boolean;
    isAdmin:boolean;
  }
