import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppMain } from 'src/app/app.main';
import { SvcAuth } from 'src/app/_svcs/auth.svc';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  returnUrl: string = "Storelist";
  isLoading: boolean = false;

  formData: login;

  constructor(
    public appMain: AppMain,
    public http: HttpClient,
    private router: Router,
    private svcAuth: SvcAuth,
  ) { }

  ngOnInit(): void {
    this.formData={password:"",userName:""}
  }
  login(){
    this.isLoading = true;
    var _userName = this.formData.userName;
    if(!_userName.includes('@'))
      _userName += '@ebrochure.com';
    this.svcAuth.login(_userName, this.formData.password).then(res=>{
      this.isLoading = false;
      console.log(res.user.uid);
      this.svcAuth.accInfoSet(JSON.stringify({uid: res.user.uid, email: res.user.email}));
      this.router.navigate(["/home"]);
    }).catch(err=>{
      console.log(err);
      this.isLoading = false;
      alert('xLogin failed. Tray again!');
    })
  }
  loginV01() {
    this.isLoading = true;
    this.Login(this.formData.userName, this.formData.password).then((res: any) => {
      if (res.status) {
        localStorage.setItem('boUserToken', res.token);
        // afer login store the current user details in local storeage
        this.getCurrentUserDetail(this.formData.userName).then((res: any) => {
          if (res.status) {
            this.appMain.isUserLoginIn = true;
            localStorage.setItem("boUserName", res.user[0].userName);
            //localStorage.setItem("GroupId", res.user[0].groupId);
            //localStorage.setItem("GroupName", res.user[0].groupName);
            localStorage.setItem("boUserId", res.user[0].employeeId);
            localStorage.setItem("boFirstName", res.user[0].employeeName);
            //localStorage.setItem("boLastName", res.user[0].lastName);
            localStorage.setItem("IsBoLoggedIn", "1");
            //localStorage.setItem("Image", res.user[0].image)
            
            this.router.navigate([this.returnUrl]);
          }
          else {
            alert(res.message);
            this.isLoading = false;
          }
        })
      }
      else {
        this.isLoading = false;
        alert(res.message);
        return;
      }
    });
  }


  // service
  public Login(username: string, password: string) {
    const httpOptions = new HttpHeaders({
      'No-Auth': 'True',
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(username + ":" + password)
    })
      ;
    return this.http.post(this.appMain.apiURL + "token/2", "", { headers: httpOptions }).toPromise();
  }

  public getCurrentUserDetail(username:string) {
    return this.http.get(this.appMain.apiURL+"User/GetCurrentUserDetails/"+username ).toPromise();
  }

}


export interface login {
  userName: string;
  password: string;
}
