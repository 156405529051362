import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    localStorage.removeItem("boUserToken");
    localStorage.removeItem("boUserId");
    localStorage.removeItem("boIsLoggedIn");
    localStorage.removeItem("boFirstName");
    this.authService.logOut();
  }


}
