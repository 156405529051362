import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-languagelist',
  templateUrl: './languagelist.component.html',
  styleUrls: ['./languagelist.component.scss']
})
export class LanguagelistComponent implements OnInit {

  menus: any[];
  dataSource: any;
  @ViewChild("grid") grid: DxDataGridComponent;
  
  constructor(
    public service: LanguageService,
    private router: Router
  ) {
    this.menus = [
      // {
    //   languageId: "addNew",
    //   name: "Add New",
    //   icon: "add",
    //   location: "after"
    // },
    {
      languageId: "view",
      name: "View",
      icon: "search",
      location: "after"
    },
    // {
    //   languageId: "refresh",
    //   name: "Refresh",
    //   icon: "refresh",
    //   location: "after"

    // },
    {
      languageId:"delete",
      name: "Delete",
      icon: "trash",
      location: "after"

    }
  ];
  }

  ngOnInit(): void {
    this.loadGrid();
  }

  loadGrid() {
    this.service.getLanguage().then((res: any) => {
      if (res.status == true) {
        this.dataSource = res.item;
      }
    })
  }
  addNew() {
this.router.navigate(["Language"]);
  }
  view(languageId: number) {
    this.router.navigate(['Language/' + languageId]);
  }
  delete(languageId: number) {
    if ((confirm("Are you sure to delete "))) {
      for (let i = 0; i < this.dataSource.length; ++i) {
        if (this.dataSource[i].languageId === languageId) {
          this.service.deleteLanguage(languageId).then((res: any) => {
            this.dataSource.splice(i, 1);
          })
        }
      }
      }
 
  }

  menuClick(e) {
    if (e.itemData.languageId == "addNew") {
      this.addNew();
    }
   else if (e.itemData.languageId == "refresh") {
      this.loadGrid();
    }
    else if (e.itemData.languageId == "view") {
      console.log(this.grid.instance.getSelectedRowsData()[0]);
      var param = this.grid.instance.getSelectedRowsData()[0].languageId;
      this.view(param);
    }
    else if (e.itemData.languageId == "delete") {
      var a = this.grid.instance.getSelectedRowsData()[0].languageId;
      this.delete(a);
    }
  }
  

}
