import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMain } from 'src/app/app.main';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    public appMain: AppMain,
    public http: HttpClient
  ) { }


  public Login(username: string, password: string) {
    const httpOptions = new HttpHeaders({
      'No-Auth': 'True',
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(username + ":" + password)
    });

    return this.http.post(this.appMain.apiURL + "token/2", "", { headers: httpOptions }).toPromise();
  }

  public getCurrentUserDetail(username:string) {
    
    return this.http.get(this.appMain.apiURL+"User/GetCurrentUserDetails/"+username ).toPromise();
  }

}
