<div class="top-menu">
  <dx-menu #menu [dataSource]="menus" displayExpr="name" orientation="horizontal" (onItemClick)="menuClick($event)">
  </dx-menu>
</div>
<div class="content-block dx-card responsive-paddings">
  <dx-form #form id="form" [colCount]="3" [(formData)]="formData">
    <dxi-item itemType="group" [colSpan]=3 [colCount]="1" caption="Video">
      <dxi-item dataField="videoId" [visible]=false></dxi-item>
      <dxi-item dataField="videoTitle" [label]="{text:(' Video Title')}"[editorOptions]="{ maxLength: '50'}">
        <dxi-validation-rule type="required" message="Video Title is required" >
        </dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [max]="50" message="Video Title length must be less than 50"  >
        </dxi-validation-rule>
      </dxi-item>
      <!-- <dxi-item dataField="youtubeVideoLink" [label]="{text:(' Video Video Link')}"[editorOptions]="{ maxLength: '500'}">
      </dxi-item> -->
      <dxi-item dataField="isActive" editorType="dxCheckBox"></dxi-item>        
    </dxi-item>
    <dxi-item itemType="group" [colSpan]=3 [colCount]="1">
      <dxi-item itemType="tabbed" [tabPanelOptions]="{ deferRendering: false }">
        <dxi-tab title="Video">  
          <dx-file-uploader
            #fileUploader
            [multiple]="false"
            accept=".mp4"
            [(value)]="value"
            [chunkSize]="200000"
            uploadMode="useButtons"
            (onOptionChanged)=" loadDocumentImage($event)"
            (onFileUploaded)="onUploaded($event)"></dx-file-uploader>
              
          <!-- <dx-file-uploader
          #fileUploader
          [uploadUrl]="uploadAPI"
          [multiple]="false"
          accept=".mp4"
          [(value)]="value"
          [chunkSize]="200000"
          uploadMode="instantly"
          (onOptionChanged)=" loadDocumentImage($event)"
          (onFileUploaded)="onUploaded($event)"></dx-file-uploader> -->

          <!-- <div *ngIf="formData.videoDocument">
            <a href="{{videoURL+formData.videoDocument}}" target="_blank"> Selected File Name : {{formData.videoDocument}}</a>
            <br>
          </div> -->
          <div *ngIf="videoFilePath && videoFilePath.length>0">
            <a href="{{videoFilePath}}" target="_blank"> Selected File Name : {{videoFileName}}</a>
            <br>
          </div>
          <div class="help">
            <span class="note">Maximum file size: <span>200 MB</span>.</span>
          </div>
        </dxi-tab>
      </dxi-item>
    </dxi-item>
  <dxi-item itemType="group" [colSpan]=3 [colCount]="1">
    <dxi-item itemType="tabbed" [tabPanelOptions]="{ deferRendering: false }">
    
      <dxi-tab title="VideoStore">
        
        <dx-data-grid  [dataSource]="formData.videoStore" keyExpr="videoId" [focusedRowEnabled]="true"
          showBorders="true" [columnAutoWidth]="true">
          <dxo-editing 
          mode="row"
          [allowUpdating]="true"
          [allowDeleting]="true"
          [allowAdding]="true">
     </dxo-editing>
          <dxi-column dataField="videoId" [visible]="false" ></dxi-column>
          <dxi-column dataField="storeId" caption="Store Name">
            <dxo-lookup [dataSource]="Stores" valueExpr="storeId" displayExpr="storeName">
            </dxo-lookup>
            <dxi-validation-rule type="required" message="Store Name  is required">
            </dxi-validation-rule>
        </dxi-column>
         
        </dx-data-grid>
      </dxi-tab>
      
    </dxi-item>
  </dxi-item> 
    </dx-form>
</div>
