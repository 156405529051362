<div class="content-block dx-card responsive-paddings">
  <dx-form #form id="form" [colCount]="3" [(formData)]="formData">
    <dxi-item itemType="group" [colSpan]=3 [colCount]="1" caption="Change-Password">

      <dxi-item dataField="OldPassword" [label]="{text:('Current Password')}" [editorOptions]="{ mode: 'password' }">
        <dxi-validation-rule type="required" message="Current Password  is required">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="Password" [label]="{text:('New Password')}" [editorOptions]="{ mode: 'password' }">
        <dxi-validation-rule type="required" message="New Password name is required">
        </dxi-validation-rule>
        <dxi-validation-rule type="required" message="User Name name is required">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="ConfirmPassword" [label]="{text:('Confirm Password')}"
        [editorOptions]="{ mode: 'password' }">
        <dxi-validation-rule type="required" message="Confirm Password name is required">
        </dxi-validation-rule>
      </dxi-item>
    </dxi-item>
  </dx-form>

  <dx-button style="float: right;" stylingMode="outlined" text="Submit"  type="normal" [width]="120" (onClick)="change()">
  </dx-button>
</div>