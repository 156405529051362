
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { VideoService } from '../video.service';

@Component({
  selector: 'app-videolist',
  templateUrl: './videolist.component.html',
  styleUrls: ['./videolist.component.scss']
})
export class VideolistComponent implements OnInit {
  menus: any[];
  dataSource: any;
  records = [];
  @ViewChild("grid") grid: DxDataGridComponent;

  constructor(
    public service:VideoService, private svcAku: SvcAku,
    public db: AngularFireDatabase, public afStore: AngularFireStorage,
    private router: Router
  )  {
    this.menus = [
      { videoId: "addNew", name: "Add New", icon: "add", location: "after" },
      { videoId: "edit", name: "Edit", icon: "edit", location: "after" },
    // { videoId: "refresh", name: "Refresh", icon: "refresh", location: "after" },
      { videoId: "delete", name: "Delete", icon: "trash", location: "after" }
    ];
  }

  ngOnInit(): void {
    this.loadGrid();
    this.svcAku.log('P-VIDEO-LIST');
  }
  loadGrid() {
    this.db.list('videos').snapshotChanges().subscribe(res=>{
      this.records = this.svcAku.fireRecords(res);
      console.log(this.records);
      var _records = [];
      this.records.forEach(r=>{
        if(r.s == 2) return;
        _records.push({
          videoId: r.id,
          videoTitle: r.n,
          videoDocument: r.fn,
          isActive: r.s == 1
        });
      });
      this.dataSource = _records;
    });
    // this.service.getVideo().then((res: any) => {
    //   if (res.status == true) this.dataSource = res.item;
    // })
  }
  addNew() {
    this.router.navigate(["Video"]);
  }
  edit(videoId: number) {
    this.router.navigate(['Video/' +videoId]);
  }
  delete(videoId: number) {
    if ((confirm("Are you sure to delete?"))) {
      var _arr = this.records.filter(x=>x.id == videoId);
      if(_arr.length<1){
        alert('Record not found!');
        return;
      }
      this.db.object(`videos/${_arr[0].appId}`).update({s:2}).then(()=>{

      }).catch(()=>{
        alert('Error deleting the video. Please try again!');
      });
      // for (let i = 0; i < this.dataSource.length; ++i) {
      //   if (this.dataSource[i].videoId=== videoId) {
          
      //     // this.service.deleteVideo(videoId).then((res: any) => {
      //     //   this.dataSource.splice(i, 1);
      //     // })
      //   }
      // }
    }
  }
  menuClick(e) {
    if (e.itemData.videoId == "addNew") {
      this.addNew();
    }
    else if (e.itemData.videoId == "refresh") {
      this.loadGrid();
    }
    else if (e.itemData.videoId == "edit") {
      console.log(this.grid.instance.getSelectedRowsData()[0]);
      var param = this.grid.instance.getSelectedRowsData()[0].videoId;
      this.edit(param);
    }
    else if (e.itemData.videoId == "delete") {
      var a = this.grid.instance.getSelectedRowsData()[0].videoId;
      this.delete(a);
    }
  }
  Rowdblclick() {
    var param = this.grid.instance.getSelectedRowsData()[0].videoId;
    this.edit(param);
  }
  onContentReady(e) {
    
      this.grid.instance.selectRowsByIndexes([0]);
  }
}
