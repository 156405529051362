<div class="content-block dx-card responsive-paddings">
  <dx-form #form id="form" [colCount]="3" [(formData)]="formData">
    <dxi-item itemType="group" [colSpan]=3 [colCount]="1" caption="Configs">
       
      <dxi-item dataField="showLanguageOptions" editorType="dxCheckBox"></dxi-item>
      <dxi-item dataField="showBrochures" editorType="dxCheckBox"></dxi-item>
      <dxi-item dataField="showVideos" editorType="dxCheckBox"></dxi-item>

      <dxi-item dataField="backgroundColor" [label]="{text:('Background Color')}" [editorOptions]="{ mode: 'text' }">
        <dxi-validation-rule type="required" message="Background Color is required">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="bannerColor" [label]="{text:('Banner Color')}" [editorOptions]="{ mode: 'text' }">
        <dxi-validation-rule type="required" message="Banner Color is required">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="footerColor" [label]="{text:('Footer Color')}" [editorOptions]="{ mode: 'text' }">
        <dxi-validation-rule type="required" message="Footer Color is required">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="titleColor" [label]="{text:('Title Color')}" [editorOptions]="{ mode: 'text' }">
        <dxi-validation-rule type="required" message="Title Color is required">
        </dxi-validation-rule>
      </dxi-item>

      <!-- <dxi-item dataField="Password" [label]="{text:('New Password')}" [editorOptions]="{ mode: 'password' }">
        <dxi-validation-rule type="required" message="New Password name is required">
        </dxi-validation-rule>
        <dxi-validation-rule type="required" message="User Name name is required">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="ConfirmPassword" [label]="{text:('Confirm Password')}"
        [editorOptions]="{ mode: 'password' }">
        <dxi-validation-rule type="required" message="Confirm Password name is required">
        </dxi-validation-rule>
      </dxi-item> -->
    </dxi-item>
  </dx-form>

  <dx-button style="float: right;" stylingMode="outlined" text="Submit"  type="normal" [width]="120" (onClick)="change()">
  </dx-button>
</div>